.tableContainer {
  width: 100%;
  height: 100%;
}
.headerCont {
  height: 44px;
  width: 100%;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border: 1px solid #e6e6e6;
  display: flex;
}
.headerCont1 {
  height: 56px;
  width: 100%;
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  display: flex;
}
.head1 {
  width: 50%;
  padding: 12px;
  border-right: 1px solid #e6e6e6;
  border-top-left-radius: 6px;
  background-color: #f6f6f6;
}
.head3:hover {
  background-color: #E6E6E6;
}
.head2:hover {
  background-color: #E6E6E6;
}
.head1:hover {
  background-color: #E6E6E6;
}
.head2 {
  width: 25%;
  padding: 12px;
  border-right: 1px solid #e6e6e6;
  background-color: #f6f6f6;
}
.head3 {
  width: 25%;
  padding: 12px;
  background-color: #f6f6f6;
  border-top-right-radius: 6px;
}
.heightSet {
  height: 54.3vh;
}
.heightSet :last-child {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.head11 {
  width: 50%;
  padding: 8px 12px 8px 12px;
  border-right: 1px solid #e6e6e6;
}
.head22 {
  width: 25%;
  padding: 17px 12px 8px 12px;
  border-right: 1px solid #e6e6e6;
}
.head33 {
  width: 25%;
  padding: 17px 12px 8px 12px;
}

.disClFlex {
  display: flex;
  flex-direction: column;
}
