.title {
  display: flex;
}

.filter {
  gap: 8px;
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.dateFieldLabelMainContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding: 16px
}

.dateFieldLabelContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
}

.dateFieldContainer {
  display: flex;
  flex-direction: column;
}

.filterApplyButton {
  margin: 0px 16px 16px auto;
}

.multipleCheckboxMainContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  padding: 16px;
}

.containCheckbox {
  width: 11rem;
}