.div {
  border-bottom: 1px solid #D3DAE3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.containStep {
  padding: 1rem;
}

.subTitles {
  margin-top: 1rem;
  font-size: clamp(12px, 0.729vw, 30px);
  font-weight: 500;
  align-self: center;
  color: #212833;
  text-transform: capitalize;
}

.socialAuthBtn {
  background: white;
  box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.04), 0px 1px 1px rgba(24, 24, 28, 0.04);
  border: 1px solid #E6E6E6;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  border-radius: 6px;
  width: 100%;
  height: 40px;
  padding: 15px;
  white-space: nowrap;
  justify-content: center;
  color: #222124;
  cursor: pointer;
}


.aiContainer {
  background: #F8F8F8;
  display: flex;
  width: 100%;
  height: calc(100% - 130px);
}

.aiLeftSection {
  background: white;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: .833vw;
  width: 100%;
  height: 100%;
}

.aiForm {
  width: 35%
}

.aiRightSection {
  padding: .833vw;
  width: 65%;
}

.aiGapInside {
  display: flex;
  flex-direction: column;
  gap: 4px;
  white-space: nowrap;
}

.aiRightInside {
  margin-top: 6px;
  border: 1px solid #E0E0E0;
  border-radius: 6px 6px 0 0;
  background: white;
  height: calc(100% - 144px);
  overflow: auto;
  padding: 0 .833vw .833vw .833vw;
}

#unique_dialog {
  overflow: auto;
}

.aiRightInside::-webkit-scrollbar {
  width: 5px;
  height: 5px
}

.aiRightInside::-webkit-scrollbar-thumb {
  background: #CBCBCB;
  border-radius: 3px;
}

.aiRightInside::-webkit-scrollbar-thumb:hover,
.aiRightInside::-webkit-scrollbar-thumb:hover {
  background-color: #5A5C60;
}

.aiRightNoContent {
  margin-top: 6px;
  border: 1px solid #E0E0E0;
  border-radius: 6px 6px 0 0;
  background: white;
  height: calc(100% - 34px);
  padding: .833vw;
}

.snippet {
  position: relative;
  background: #fff;
  padding: 32px 5%;
  margin: 24px 0;
  box-shadow: 0 4px 12px -2px rgba(0, 32, 128, .1), 0 0 0 1px rgba(60, 80, 120, 0.1);
  border-radius: 16px;
}

.snippet::before {
  display: inline-block;
  position: absolute;
  top: 6px;
  left: 6px;
  padding: 0 8px;
  content: attr(data-title);
  font-size: 12px;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  color: white;
  background-color: rgb(255, 25, 100);
  border-radius: 10px;
  line-height: 20px;
}

.snippet:hover {
  cursor: pointer;
  outline: 2px solid rgb(255, 25, 100);
}

.stage {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 32px 0;
  margin: 0 -5%;
  overflow: hidden;
}

.dotPulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dotPulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
}

.dotPulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse-before 1.5s infinite linear;
  animation-delay: 0s;
}

.dotPulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }

  30% {
    box-shadow: 9984px 0 0 2px;
  }

  60%,
  100% {
    box-shadow: 9984px 0 0 -5px;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }

  30% {
    box-shadow: 9999px 0 0 2px;
  }

  60%,
  100% {
    box-shadow: 9999px 0 0 -5px;
  }
}

@keyframes dotPulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }

  30% {
    box-shadow: 10014px 0 0 2px;
  }

  60%,
  100% {
    box-shadow: 10014px 0 0 -5px;
  }
}

.chat {
  background-color: #E6F8F1;
  padding: 16px 28px;
  -webkit-border-radius: 20px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius: 20px;
  -moz-border-radius-bottomleft: 2px;
  border-radius: 20px;
  border-bottom-left-radius: 2px;
  display: inline-block;
}

.typing {
  align-items: center;
  display: flex;
  height: 17px;
}

.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: #6CAD96;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}

.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}

.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}

.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}

.typing .dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: #6CAD96;
  }

  28% {
    transform: translateY(-7px);
    background-color: #9ECAB9;
  }

  44% {
    transform: translateY(0px);
    background-color: #B5D9CB;
  }
}

.dots7 {
  width: 45px;
  aspect-ratio: 4;
  --_g: no-repeat radial-gradient(circle closest-side, #DBDBDB 65%, #FFFFFF);
  background:
    var(--_g) 0% 50%,
    var(--_g) 50% 50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 100%;
  animation: d7 1.5s infinite linear;
  margin-top: 22px;
}

@keyframes d7 {
  33% {
    background-size: calc(100%/3) 0%, calc(100%/3) 100%, calc(100%/3) 100%
  }

  50% {
    background-size: calc(100%/3) 100%, calc(100%/3) 0%, calc(100%/3) 100%
  }

  66% {
    background-size: calc(100%/3) 100%, calc(100%/3) 100%, calc(100%/3) 0%
  }
}

.dflx {
  display: flex;
  gap: 4px;
}


.subjectInside {
  margin-top: .833vw;
  border: 1px solid #E0E0E0;
  border-radius: 6px 6px 6px 6px;
  background: white;
  display: flex;
  white-space: nowrap;
  overflow-x: auto;
  gap: 4px;
  align-items: center;
  padding: .833vw;
}

.footerPreviewInside {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid #E0E0E0;
  border-left: 1px solid #E0E0E0;
  border-right: 1px solid #E0E0E0;
  padding: 0 12px 0 0;
  gap: 8px;
  background: white;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.centerData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.paddingTopAI {
  padding-top: 16px;
}

.paddingTopAIData {
  padding-top: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.socialAuthIcon {
  margin-right: 12px;
  object-fit: contain;
  width: clamp(1.4rem, 1.7vw, 3.5rem);
  height: clamp(1.4rem, 1vh, 2.5rem);
}

.mTop {
  margin-top: 8px;
}

.containEachInfoo {
  padding-top: 1rem;
  width: initial;
  overflow: auto;
}

.sendViaContainer {
  padding-top: 14px;
  padding-right: 20px;

  @media (min-width: 1200px) and (max-width: 1600px) {
    padding-top: 10px;
    padding-right: 10px;
  }
}

.containNavigationBtns {
  display: flex;
  height: 90%;
}

.segementList {
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.mailStyle {
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 50%;
}

.bodyContainer {
  height: calc(100% - 95px);
}

.bodyContainerEsp {
  height: calc(100% - 95px);
  display: flex;
  gap: 8px;

}

.stepTwoLabelSequence {
  font-size: clamp(12px, 0.729vw, 30px);
  font-weight: 500;
  align-self: center;
  color: #212833;
}

.paperMenu1 {
  width: 21vw;
  height: 20vh;
  margin-top: 3px;
  overflow: auto;
}

.paperMenu2 {
  width: 55vw;
  height: 20vh;
  margin-top: 3px;
  overflow: auto;
}



.paperMenu {
  width: max-content;
  overflow: auto;
  height: 20vh;

}

/* SCROLL_BAR styles */
.paperMenu2::-webkit-scrollbar,
.paperMenu1::-webkit-scrollbar,
.paperMenu::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.paperMenu2::-webkit-scrollbar-track,
.paperMenu1::-webkit-scrollbar-track,
.paperMenu::-webkit-scrollbar-track {
  height: 5px;
}

.paperMenu2::-webkit-scrollbar-thumb,
.paperMenu1::-webkit-scrollbar-thumb,
.paperMenu::-webkit-scrollbar-thumb {
  background: "#CBCBCB";
  border-radius: 3px;
}

.paperMenu2::-webkit-scrollbar-thumb:hover,
.paperMenu2 *::-webkit-scrollbar-thumb:hover,
.paperMenu1::-webkit-scrollbar-thumb:hover,
.paperMenu1 *::-webkit-scrollbar-thumb:hover,
.paperMenu::-webkit-scrollbar-thumb:hover,
.paperMenu *::-webkit-scrollbar-thumb:hover {
  background-color: "#5A5C60";
}

.stepTwoLabelLists {
  margin-top: 20px;
  font-size: clamp(12px, 0.729vw, 30px);
  font-weight: 500;
  align-self: center;
  color: #212833;
}

.skipContactText {
  margin-top: 36px;
  height: 39vh;
  color: #ff4848;
  font-size: clamp(12px, 0.9vw, 36px);
  text-align: center;
  font-weight: 500;
}

.timingBodyContainer {
  display: flex;
  gap: 16px;
}

.widthStyle {
  width: 50%;
}

.flexJustifyBetween {
  display: flex;
  justify-content: space-between;
}

.bgcol {
  background-color: white;
}

.timeSelection {
  margin-top: 15px;
  margin-left: 0;
  border-radius: 4px;
}

.containerTimeSel {
  border: 1px solid #d3dae3;
  border-radius: 6px;
}

.flex {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  border-bottom: 1px solid #d3dae3;
  padding: 2px 20px;
}

.containEach {
  display: flex;
  gap: 4px;
}

.subHeadings {
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: clamp(12px, 0.729vw, 30px);
  font-weight: 500;
  color: #212833;

  @media (max-width: 1470px) {
    font-size: clamp(0.8rem, 0.7vw, 3rem);
  }
}

.WeekDays {
  padding: 1rem;
  background-color: white;
  overflow: auto;
  margin-top: 1rem;
}

.WeekDays::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}

.containDates {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 0px;
}

.containDates2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 10px;
}

.containDates3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-bottom: 0.5rem;
}

.eachDate {
  font-size: clamp(12px, 0.729vw, 30px);
  font-weight: 500;
  text-transform: capitalize;
  align-self: center;
  color: #212833;
}

.containDropDown {
  margin-right: 5px;
}

.colonColor {
  color: #626B7E;
}

.editScheduleButtonContainer {
  background-color: white;
  display: flex;
  justify-content: flex-end;
  padding: 10px 16px 13px 0px;
  column-gap: 10px;
}

.editScheduleButtonContainer2 {
  margin-top: -17px;
  border: 1px solid #EFEFEF;
  border-top: 0px;
  background-color: white;
  display: flex;
  padding: 16px 12px;
  align-items: center;
  gap: 10px;

}

.previewMainContiner {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.previewStepMainContainer {
  display: flex;
  gap: 40px;
  align-items: baseline;
}

.previewStepDetailsContainer {
  display: flex;
  justify-content: space-between;
  gap: 14px;
}

.previewStepDetailSubContainer {
  display: flex;
  justify-content: space-between;
  gap: 4px;
  background-color: white;
  padding: 8px;
  border-radius: 6px;
  box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.04), 0px 1px 1px rgba(24, 24, 28, 0.04);
}

.previewStepDetailSubContainer1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.04), 0px 1px 1px rgba(24, 24, 28, 0.04);
}

.previewStepLabel {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: clamp(12px, 0.729vw, 30px);
  color: #000000;
  white-space: nowrap;
}

.previewStepDetailsMainContainer {
  background-color: white;
  padding-bottom: 20px;
  box-shadow: 0px 2px 3px rgba(33, 40, 51, 0.12);
  border-radius: 6px;

  @media (max-width: 1600px) {
    padding-bottom: 10px;
  }
}

.previewStepDetailsContainer1 {
  display: flex;
  gap: 14px;
}

.previewStepDetailSubContainer1 {
  display: flex;
  justify-content: space-between;
  gap: 4px;
  background-color: white;
  padding: 8px;
  border-radius: 6px;
  box-shadow: none;
}

.previewStep4DetailsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 12px 8px;
}

.previewStep4DetailLowerContainer {
  display: flex;
  flex-wrap: wrap;
  column-gap: 50px;
  row-gap: 16px;
}

.previewStep4DetailsLabel {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: clamp(12px, 0.729vw, 30px);
  color: #000000;
  text-transform: capitalize;
}

.previewStepDetailsTimeValue {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: clamp(8px, 0.625vw, 24px);
  color: #626B7E;
  padding-top: 6px;
}

.campBtnStyle {
  display: flex;
  column-gap: 10px;
}

.backBtnContainer {
  padding-left: 40px;

  @media (min-width: 1900px) {
    padding-left: 133px;
  }
}

.heightStyle {
  height: 100%;
}

.stepper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.stepsContainer {
  display: flex;
  flex-direction: row;
}

.stepperdiv {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #D3DAE3;
  padding: 1rem 0rem;
  width: 30%;
  /* gap: 1rem; */
}

.marginDiv {
  margin: 0 0 0 15px;
}

.steplab {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  padding-right: 1rem;
}

.steplabs {
  margin: 0;
  margin-top: 6px;
  font-family: Inter;
  font-weight: 500;
  font-size: clamp(12px, 0.729vw, 30px);
  color: #212833;
}

.divv {
  z-index: 2;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  margin-top: 4px;
}

.MarginImage {
  margin-bottom: -4px;
  width: 16px;
  height: 16px;
}

.containEachStep {
  background: #F5F5F5;
  width: -webkit-fill-available;
  overflow: auto;
  overflow: auto;
}


.modalConiner {
  padding: 0;
}

.bgColSkip {
  background: #F3F5F7;
}

.bgColTab {
  background: white;
}

.wAuto {
  width: auto;
}

.containTableHeads {
  display: grid;
  background: #F6F6F6;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  /* padding: 1rem; */
  /* border-radius: 4px 4px 0 0; */
}

.displayFlex {
  display: flex;
}

.tableHead {
  color: #212833;
  font-size: clamp(12px, 0.729vw, 30px);
  font-weight: 500;
}

.padSett {
  padding-top: 8px;
}

.tableBodyContainer {
  overflow: auto;
  height: 37vh;
  overflow: auto;

}

.eachTableRow {
  display: grid;
  padding: 1rem;
  background: white;
  border-bottom: 1px solid #E6E6E6;
}

.eachTableRecord {
  color: #626B7E;
  font-size: clamp(12px, 0.729vw, 30px);
  font-weight: 400;
}

.eachTableRecord1 {
  color: #626B7E;
  font-size: clamp(12px, 0.729vw, 30px);
  font-weight: 400;
  padding-left: 1rem;
}

.containCommonTable {
  margin-top: 24px;
  width: 100%;
  border: 1px solid #E6E6E6;
  border-radius: 6px;
  background-color: white;
  overflow: auto;

}

.statsContainer {
  display: flex;
  width: 100%;
  height: -webkit-fill-available;
}

.containSteps {
  width: 13%;
  background: #F8F8F8;
}

.eachSelectedStatStep {
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  background: #ffffff;
  box-shadow: inset 2px 0px 0px 0px #2080f6;
}

.eachStatStep {
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
  padding: 1rem 1rem 1rem 1rem;
}

.eachStatStep1 {
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
  padding: 0 1rem 1rem 1rem;
}

.stepSaperator {
  height: 1rem;
  border-left: 1px solid #DBDBDB;
  margin: 1rem;
  margin-left: 53px;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  width: 87%;
  height: 100%;
}

.statsCountContainer {
  display: flex;
  gap: 1.458vw;
  justify-content: center;
  padding: 1rem 0;
  overflow-x: auto;
}

.eachCountContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 1rem;
  border-right: 1px solid #E6E6E6;
  cursor: pointer;
}

.selectedType {
  box-shadow: inset 0px -2px 0px 0px #FF4848;
}

.tableContainer {
  border: 1px solid #E6E6E6;
  margin: 1rem;
  height: 100%;
  margin-top: 0;
}

.tableContainerSt {
  border: 1px solid #E6E6E6;
  margin: 1rem;
  height: calc(100% - 100px);
  margin-top: 0;
}

.footerContainer {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

.displayFooter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.tempCon {
  height: 100vh;
}

.crtTemplateBodyContainer {
  height: calc(100vh - 180px);
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 16px;
  gap: 1rem;
}

.templateCreationPage {
  width: 100%;
  height: calc(100vh - 180px);
  /* height: 100%; */
  box-sizing: border-box;
  overflow: auto;
  background: #ffffff;
  border-radius: 6px;

}

.headerCreateTemplate {
  display: flex;
  justify-content: space-between;
  background-color: #FFFFFF;
  border-bottom: 1px solid #D3DAE3;
  align-items: center;
  padding: 16px;
}

.heightFix {
  height: calc(100%-64px);
}

.headerTempContent2 {
  color: #0F2F51;
  font-weight: 600;
  font-size: clamp(16px, 1.042vw, 30px);
}

.commonMargin {
  padding-top: 16px;
  padding-right: 16px;
  padding-left: 16px;
}

.commonLabel {
  color: #212833;
  font-size: clamp(12px, 0.729vw, 30px);
  font-weight: 500;
}

.templateTextStyle {
  padding-top: 4px;
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
}

.ownerTextStyle {
  display: flex;
  justify-content: flex-start;
  padding-top: 16px;
  padding-right: 16px;
  padding-left: 16px;
  column-gap: 10px;
  width: 100%;
}

.widthCommon {
  width: 50%;
}

.commonTextBoxes1 {
  padding-top: 4px;
}

.modalPadding {
  padding-top: 16px;
  padding-left: 16px;
  width: 100%;
}

.modalRadio1 {
  padding-left: 16px;
  padding-top: 4px;
}

.modalRadio {
  padding-left: 16px;
}

.paddingT {
  padding-top: 16px;
}

.templateTextStyle {
  padding-top: 4px;
  padding-right: 16px;
  padding-left: 16px;
  width: 100%;
}

.commonTextBoxes3 {
  margin: 4px 16px 16px 16px;
  border-radius: 6px;
  border: 1px solid #D3DAE3;
}

.previewTemplateContainer {
  width: 100%;
  box-sizing: border-box;
  height: calc(100vh - 230px);
  /* height: 100%; */
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 12px 8px 0px rgba(24, 24, 28, 0.02), 0px 0px 16px 0px rgba(24, 24, 28, 0.12);
}

.mainPrevCont {
  width: 100%;
  box-sizing: border-box;
  height: calc(100vh - 180px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sendMailStyPrev {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: .833vw;
  padding: .833vw;
  background-color: #FFFFFF;
  border-radius: 6px;
}

.flexStylePrev {
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
}

.flexStylePrevBtn {
  display: flex;
  align-items: center;
  gap: 8px;
  /* white-space:nowrap; */
}

.flexStylePrev1 {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* gap:4px; */
  /* white-space:nowrap; */
}

.heightSet {
  height: calc(100vh - 180px);
  overflow: auto !important;
  border-radius: 6px;
  box-shadow: 0px 24px 48px -8px rgba(24, 24, 28, 0.11), 0px 48px 64px -36px rgba(24, 24, 28, 0.13);
}

.prevHead {
  padding: 16px;
  background: #5A5C60;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.commonDataPrev {
  padding: 16px;
  border-bottom: 1px solid #D3DAE3;
}

.commonPreviews {
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.wordBreak {
  word-break: break-word;
}

.bodyData {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 216px) !important;
  gap: 30px;
  word-break: break-word;
  overflow-y: auto;

}

.previewMailBodyData {
  font-size: clamp(12px, 0.729vw, 30px);
}

.containBtn {
  width: 30%;
  padding-left: 1rem;
  padding-bottom: 1rem;
}

.footerCreateTemplate {
  background: #FFFFFF;
  border-radius: 6px;
  padding: 16px;
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  align-items: center;
  margin: 0 16px;
}

.padRight {
  padding-right: 16px;
}

.disFlex {
  display: flex;
  gap: 4px;
  align-items: center;
}

.modalTitle {
  font-size: clamp(1rem, 1.042vw, 2.5rem);
  font-weight: 600;
  color: #102f51;
}

.bodyPrevw {
  margin-top: 1rem;
  padding: 1rem 0;
  border-top: 1px solid #EFEFEF;
  /* height: 40vh; */
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

}

.rightFoot {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  /* padding:16px; */
  /* border-top: 1px solid #EFEFEF; */
}

.modalBodyContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
}

.modalPadTop {
  padding-top: 10px;
}

.modalPaddingTop {
  padding-top: 12px;
}

.modalRad {
  display: flex;
  flex-direction: row;
  padding-top: 4px;
  gap: 15px;
}

.modalRad1 {
  display: flex;
  flex-direction: row;
  padding-top: 6px;
  gap: 15px;
}

.eachStepContainer {
  background: #ffffff;
  box-shadow: 0px 4px 8px -2px rgba(24, 24, 28, 0.1), 0px 2px 4px -2px rgba(24, 24, 28, 0.06);
  border-radius: 6px;
}

.emailStepHeader {
  background: #EBF1FD;
  padding: 8px 16px 8px 16px;
  border-radius: 6px 6px 0 0;
  display: flex;
  justify-content: space-between;
}

.callStepHeader {
  background: #FCF1F7;
  padding: 8px 16px 8px 16px;
  border-radius: 6px 6px 0 0;
  display: flex;
  justify-content: space-between;
}

.todoStepHeader {
  background: #FFF5E6;
  padding: 8px 16px 8px 16px;
  border-radius: 6px 6px 0 0;
  display: flex;
  justify-content: space-between;
}

.stepLeft {
  display: flex;
  gap: 6px;
}

.stepBorder {
  display: flex;
  gap: 6px;
  align-items: center;
}

.vl {
  border-left: 1px solid #DBDBDB;
  height: 18px
}

.stepNumberChip {
  display: flex;
  align-items: center;
}

.emailStepCounter {
  background: #2080F6;
  padding: 3px 12px !important;
  border-radius: 27px;
}

.callStepCounter {
  background: #E64593;
  padding: 3px 12px !important;
  border-radius: 27px;
}

.todoStepCounter {
  background: #F4A118;
  padding: 3px 12px !important;
  border-radius: 27px;
}

.bodyContain {
  padding: 1rem;
  max-height: 16rem;
  overflow: auto;
}

.footerContainer {
  padding: 8px 20px 8px 20px;
  border-top: 1px solid #EFEFEF;
  border-radius: 0 0 6px 6px;
  display: flex;
  justify-content: space-between;
}

.footerContainerNoTop {
  padding: 8px 20px 8px 20px;
  border-radius: 0 0 6px 6px;
  /* border-top: 1px solid #EFEFEF; */
  display: flex;
  justify-content: space-between;
}

.footerContainerUn {
  /* padding: 1rem; */
  display: flex;
  justify-content: space-between;
}

.gapFlex {
  display: flex;
  gap: 8px;
}

.containSkeleton {
  padding: 0 12px 12px 12px;
}

.flexStats {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.containSkeletonStat {
  padding: 0 1rem;
}

.chip {
  display: flex;
  gap: 4px;
  align-items: center;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: red;
}

.loaderContainer {
  overflow: auto;
  height: calc(100% - 12rem);
}

.flexContainerStats {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1rem;
}

.stepBody {
  background: white;
  border-bottom: none;
  padding: 1rem;

}

.eachStepDetail {
  display: flex;
  gap: 8px;
  padding-bottom: 1rem;
}

.eachStepDetailKey {
  color: #102f51;
  font-weight: 500;
  font-size: clamp(14px, 0.833vw, 30px);
}

.notesText {
  padding: 1rem;
  font-size: clamp(12px, 0.833vw, 30px);
  padding-left: 0;
  color: #212833;
  word-wrap: break-word;
}

.cursor {
  cursor: pointer;
}

.eachStepDetailValue {
  color: "#102f51";
  font-weight: 400;
  padding-left: 4px;
  font-size: clamp(12px, 0.729vw, 30px);
  align-self: center;
}

.containNotes {
  padding: 20px 0px 0px 0px;

  @media (min-width: 1200px) and (max-width: 1600px) {
    padding: 10px 0px 0px 0px;
  }
}

.showMoreBtn {
  font-weight: 400;
  font-size: clamp(12px, 0.833vw, 14px);
  color: #FF4848;
  margin-top: 4px;
  cursor: pointer;
}

.stepTypeBox {
  display: flex;
  padding: 1rem;
  margin: 1rem 1rem;
  flex-direction: column;
}

.containImage {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  align-self: center;
}

.descContain {
  padding: 1rem 0;
}

.txtAlgn {
  text-align: center;
}

.justifyContentCenter {
  display: flex;
  justify-content: center;
}

.linePadding {
  width: 100%;
  border-bottom: 0.1px solid #D3DAE3;
  padding-bottom: 1vw;
}

.containEditor {
  margin-top: 8px;
  border: 1px solid #d3dae3;
  min-height: 15rem;
  border-radius: 6px;
}

.btnFooter {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 8px;
}

.modalPadding2 {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.containShared {
  padding-top: 1rem;
}

.flexAlignItemsCenter {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.footerRightData {
  padding-left: 4px;
}

.padding16 {
  padding: 4px 16px 16px 16px;
}

.btnDesign {
  background: #FF4848;
  border-radius: 6px;
  padding: .417vw 0.729vw .417vw 0.729vw;
  width: max-content;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.circularSize {
  width: 18px !important;
  height: 18px !important;

}

.aiTextDesign {
  display: flex;
  align-items: center;
  white-space: nowrap;
  gap: 4px;
  margin-top: 6px;

}

.vertHt {
  height: 29px;
  border-left: 1px solid #EEEEEE;
  margin-bottom: -8px;
}









.loaderContainer::-webkit-scrollbar,
.bodyContain::-webkit-scrollbar,
.bodyPrevw::-webkit-scrollbar,
.containCommonTable::-webkit-scrollbar,
.tableBodyContainer::-webkit-scrollbar,
.containEachStep::-webkit-scrollbar,
.bodyData::-webkit-scrollbar,
.templateCreationPage::-webkit-scrollbar,
.heightSet::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.loaderContainer::-webkit-scrollbar-track,
.bodyContain::-webkit-scrollbar-track,
.bodyPrevw::-webkit-scrollbar-track,
.containCommonTable::-webkit-scrollbar-track,
.tableBodyContainer::-webkit-scrollbar-track,
.containEachStep::-webkit-scrollbar-track,
.bodyData::-webkit-scrollbar-track,
.templateCreationPage::-webkit-scrollbar-track,
.heightSet::-webkit-scrollbar-track {
  height: 5px;
}

.loaderContainer::-webkit-scrollbar-thumb,
.bodyContain::-webkit-scrollbar-thumb,
.bodyPrevw::-webkit-scrollbar-thumb,
.containCommonTable::-webkit-scrollbar-thumb .tableBodyContainer::-webkit-scrollbar-thumb,
.containEachStep::-webkit-scrollbar-thumb,
.bodyData::-webkit-scrollbar-thumb,
.templateCreationPage::-webkit-scrollbar-thumb,
.heightSet::-webkit-scrollbar-thumb {
  background: #626B7E;
  border-radius: 10px;
}

.dropdownlist {
  width: 100px !important
}

.copyFlex {
  display: flex;
  gap: 4px;
  align-items: center;
}

.arlBtn {
  font-family: Arial, Helvetica, sans-serif;
}

.paddSett {
  padding-top: 8px
}

.containPersonalDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.containUserModal {
  padding-top: 16px;
  overflow: auto;
}

.headingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.socialIconsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.socialIconsContainer1 {
  display: flex;
  justify-content: center;
  align-self: center;
  padding-top: 1rem;
  gap: 4px;
  padding-bottom: 32px;
}

.containFacebook,
.containTwitter,
.containLinkedin {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #DBDBDB;
  border-radius: 6px;
  padding: 3px;
  padding-bottom: 1px;
  margin: 0 0.3rem;
  align-self: center;
  cursor: pointer;
}

.socialIcon {
  color: #A1A0A3;
  font-size: 16px !important;
  height: 14px !important;
  width: 19px !important;
}

.containBasicInfo {
  padding: 0 0rem 1rem 0rem;
  border-bottom: 1px solid #D3DAE3;
  position: relative;
  width: 100%;
}

.basicInfoUnset {
  filter: unset;
}

.basicInfoBlur {
  filter: blur(8px);
}

.containCrownInfo {
  display: flex;
  padding: 1rem 4px;
  padding-bottom: 0;
}

.containEachInfo {
  display: flex;
  justify-content: space-between;
  margin-top: 0.8rem;
}

.basicInfoLeft {
  display: flex;
  gap: 8px;
  width: 100%;
}

.labelIconContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.copyIcon {
  padding: 3px;
  cursor: pointer;
  border-radius: 6px;
  display: flex;

}


.copiedTextContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 4px;
  gap: 2px;
  border-radius: 3px;
  background-color: #E8F6ED;
}

.containAccountsInfo {
  padding: 1rem 0;
  border-bottom: 1px solid #D3DAE3;
}

.containAccountsTitle {
  display: flex;
  justify-content: space-between;
}

.flexJustifyBetween1 {
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
}

.flexDis {
  display: flex;
  align-items: center;
  gap: 8px;
}

.comapnyImage {
  width: 18px;
  height: 18px;
}

.userDetailContain {
  padding-top: 20px;
}

.userEachDetailContain {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0.5rem 0;
}

.flexDisplay {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-top: 8px;
}

.containTechPara {
  padding: 0.5rem;
}

.previewLoading {
  height: 37.5vh;
  width: calc(37.5vw - 35px);
}