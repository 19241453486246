.mainContainer {
  width: 100%;
  border: 1px solid #EFEFEF;
  background: #FFFFFF;
  margin: 16px 0;
  border-radius: 6px;
}

.header {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  border: 1px solid #e6e6e6;
  background: #f6f6f6;
  padding: 12px;
  align-items: center;
}

.subHeadings {
  padding: 12px;
  align-items: center;
}

.WeekDays {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 1rem;
  background-color: white;
  overflow: auto;

}

.WeekDays::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}

.containDates2 {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  position: relative;
  align-items: center;
}

.containEach {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 4px;
  padding-left: 0px;
}

.containEach2 {
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  padding-left: 0px;
  width: 100%;
  justify-content: space-between;
}


.infoBtn {
  position: absolute;
  right: -17px;
}

.colonColor {
  color: #626B7E;
}

.editScheduleButton {
  background-color: white;
  display: flex;
  justify-content: flex-end;
  padding: 10px 16px 13px 0px;
  column-gap: 10px;
}