.superContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1%;
    height: 100%;
    position: relative;
    justify-content: flex-start;

}

.fillsWidth {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    position: relative;
}

.dateRangeTab {
    display: flex;
    width: 100%;
    padding: 8px 10px;
    justify-content: space-between;
    border-radius: var(--border-radius);
    cursor: pointer;
    border: 1px solid var(--border-default);
}

.dateRangeInterface {
    /* height: 60vh; */
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 16px rgba(24, 24, 28, 0.12), 0px 12px 8px rgba(24, 24, 28, 0.02);
    position: absolute;
    top: 41px;
    z-index: 1300;
    background-color: #FFFFFF;
    overflow-y: auto;
    overflow-x: hidden;
}

.showDates {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

.dateRangeInputs {
    width: 100%;
    /* height: 87%; */
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid lightgrey
}

.selectionList {
    list-style-type: none;
    padding-inline-start: unset;
    margin-block-start: unset;
    margin-block-end: unset;
}

.selectionListSmallWidth {
    width: 25%;
    border-right: 1px solid lightgrey;
}

.selectionListWidth {
    width: 100%;
}

.selectionItem {
    padding: 8px 10px;
    background-color: var(--base-white);
    cursor: pointer;
}

.selectedItem {
    padding: 8px 10px;
    background-color: var(--primary-50);
    box-shadow: inset 2px 0px 0px 0px var(--brand-primary);
    cursor: pointer;
}

.selectionItem:hover {
    box-shadow: inset 2px 0px 0px 0px var(--brand-primary);
}

.containsCalendar {
    width: 50%;
    height: 100%;
    justify-content: center;
}

.containsBothCalendars {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    background-color: rgb(253, 252, 252);
}

.customRangeSelector {
    width: 75%;
    height: 100%;
    flex-direction: column;
    gap: 3%;
    background-color: rgb(253, 252, 252);
}

.footerContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    gap: 16px;
    width: 100%;
    padding: 16px;
}

.footerButtonCOntainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.container {
    border-radius: 10px;
    background-color: var(--base-white);
    display: flex;
    flex-direction: column;
    padding: 10px;
    z-index: 100;
    position: relative;
}

.containsMonthSelector {
    position: absolute;
    top: 0.7vh;
    left: 0.5vw;
    width: 45%;
    height: 80%;
}

.containsYearSelector {
    position: absolute;
    top: 6vh;
    left: 8vw;
    width: 45%;
    height: 80%;

}

.controlBar {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 16px;
}

.actualCalendar {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.calendarRow {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    width: 100%;
    align-items: center;
    /* gap: 4px; */
}

.calendarCellLiveLeft {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6vw;
    height: 6vh;
    cursor: pointer;
    border-radius: var(--border-radius) 0 0 var(--border-radius);
    background-color: var(--brand-primary);
    color: white;
    padding: 6px;

}

.calendarCellLiveRight {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6vw;
    height: 6vh;
    cursor: pointer;
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
    background-color: var(--brand-primary);
    color: white;
    padding: 6px;
}

.calendarCellLiveBoth {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6vw;
    height: 6vh;
    cursor: pointer;
    border-radius: var(--border-radius);
    background-color: var(--brand-primary);
    padding: 6px;

}

.calendarCell {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6vw;
    height: 6vh;
    padding: 6px;
}

.calendarCellBetween {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6vw;
    height: 6vh;
    background-color: var(--primary-50);
    padding: 6px;

}

.calendarCellDead {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6vw;
    height: 6vh;
    color: var(--base-white);
    background-color: var(--base-white);
    padding: 6px;

}

.calendarCell:hover {
    cursor: pointer;
    background-color: var(--primary-50);
    border-radius: var(--border-radius);
}

.calendarCellHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6vw;
    height: 6vh;
    padding: 6px;

}

.arrowButtons:hover {
    cursor: pointer;
}

.monthAndYearButtons {
    display: flex;
    border: 1px solid lightgrey;
    border-radius: 6px;
    padding: 8px;
    font-size: 14px;
    justify-content: space-between;
    align-items: center;
    width: 40%;
    background-color: white;
}

.monthAndYearButtons:hover {
    cursor: pointer;
    background-color: white;
}