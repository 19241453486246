.propertiesMainContainer {
    height: calc(100vh - 6rem);
    background-color: #ffffff;
    margin: 16px 0px 16px 16px;
    border-radius: 6px;
}

.headerContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 16px;
    gap: 4px;
}

.bodyContainer {}

.tabsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #e6e6e6;
    padding: 0px 16px;
}

.tabPrimary {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 12px;
    gap: 10px;
    border-bottom: none;
    border-radius: 16px 16px 0px 0px;
    background-color: #ffffff;
    cursor: pointer;
}

.tabPrimary:hover p{
    color: #222124 !important;
}

.tabSelected {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 12px;
    gap: 10px;
    box-shadow: inset 0px -2px 0px 0px #ff4848;
    border-radius: 16px 16px 0px 0px;
    background-color: #ffffff;
    cursor: pointer;
}