.tabContainer {
  display: flex;
  gap: 1rem;
  background: #ffffff;
  padding: 1rem;
  padding-bottom: 0;
}

.paperMenu {
  width: 25vw;
  height: 25vh;
}

.paperMenu::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.eachContactHeader {
  width: 100%;
}

.flexSpaceBetween {
  display: flex;
  justify-content: space-between;
}

.footerRightData {
  display: flex;
  gap: 0.5rem;
  width: inherit;
  justify-content: flex-end;
}

.paperMenu::-webkit-scrollbar-track {
  height: 5px;
}

.paperMenu::-webkit-scrollbar-thumb {
  background: "#CBCBCB";
  border-radius: 3px;
}

.paperMenu::-webkit-scrollbar-thumb:hover,
.paperMenu *::-webkit-scrollbar-thumb:hover {
  background-color: "#5A5C60";
}

.searchAndStatusContainer {
  background: #ffffff;
  margin: 0 1rem;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  align-items: center;
  border-bottom: 1px solid #EFEFEF;
  border-radius: var(--border-radius) var(--border-radius) 0px 0px;
}

.statusContainer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.selectedTab {
  border-bottom: 1px solid #ff4848;
  padding: 0 12px 12px 12px;
  cursor: pointer;
}

.tab {
  border-bottom: 1px solid #ffffff;
  padding: 0 12px 12px 12px;
  cursor: pointer;
}

.horizontalBorder {
  border-left: 1px solid #e6e6e6;
  height: 1rem;
  margin-left: 45px;
}

.overviewStepSaperator {
  padding-bottom: 1rem;
}

.sequenceTableContainer {
  background: #ffffff;
  padding: 1rem;
}

.addStepBtnContainer {
  width: fit-content;
}

.leftHeadContainer {
  display: flex;
  gap: 0.5rem;
}

.rightHeadContainer {
  display: flex;
  gap: 0.5rem;
}

.buttonscContainer {
  padding: 1rem;
  margin: 1rem;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.container {
  padding: 0;
  width: 100%;
  height: 100vh;
}

.container1 {
  padding: 0;
  width: 100%;
  height: 100vh;
}

.listContainer {
  background: #f8f8f8;
  height: 100vh;
}

.modalBodyContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
}

.modalPadding {
  padding-top: 10px;
}

.modalRadio {
  display: flex;
  flex-direction: row;
  padding-top: 6px;
  gap: 15px;
}

.radio {
  color: #d3dae3;
  padding: 0;
  height: 15px;
  width: 15px;
  margin-right: 6px;

  &.Mui-checked {
    color: #ff4848;
  }
}

.camp {
  display: flex;
  align-items: center;
  height: 35px;
  width: 120px;
  margin-left: 5px;
  background-color: white;
  border: 1px solid #d3dae3;
  border-radius: 5px;
}

.mainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 25rem);
  overflow: auto;
}

.activities {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  gap: 8px;
  /* Add TABLE_SCROLL_BAR styles here */
}

.activitiesContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.filterContain {
  display: flex;
  margin: 0 1rem;
  width: 100%;
  max-height: 5rem;
  min-height: 5rem;
  overflow: auto;
}

.filterText {
  color: #212833;
  font-weight: 600;
  font-size: 14px;
  width: 100px;
  align-self: center;
  text-align: center;
}

.resp {
  display: flex;

  @media (max-width: 1365px) {
    display: block;
  }
}

.justifyContentCenter {
  display: flex;
  justify-content: center;
  padding-top: 1rem;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  padding: 1rem;
  min-height: 60px;
  max-height: 60px;
  align-items: center;
}

.contactsOverlay {
  display: flex;
  height: 3rem;
  background: white;
  box-shadow: 0px 2px 4px #00000008;
  margin: 1rem 1rem 0 0;
  border-radius: 6px 6px 0 0;
  border-bottom: 2px solid #ff4848;
  padding: 1rem;
  cursor: pointer;
}

.contactsText {
  font-size: 13px;
  color: #626b7e;
  font-weight: 400;
  white-space: nowrap;
  align-self: center;
}

.cleanBox {
  padding: 0;
  margin: 0;
}

.title {
  font-size: 20px;
  font-weight: 700;
  color: #102f51;
}

/* Steps Styles */

.stepsContainer {
  height: 100vh;
}

.stepsButton {
  height: 40px;
  color: #ffffff;
  background: #ff4848;
  border-radius: 6px;
  border: none;
  width: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  cursor: pointer;
  margin-top: 8px;
}

.stepsButton:hover {
  background: #0f2f51;
}

.containImage {
  display: flex;
  justify-content: center;
}

.containStepsBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100% - 7rem);
}

.containAllSteps {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.containOverviewSteps {
  width: 52vw;
  padding: 0 0 1rem 0;
}

.stepIndicatorContainer {
  position: absolute;
  top: 15px;
  left: -43px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  height: 110px;
  width: 44px;
  margin-top: 15px;

  @media (min-width: 1200px) and (max-width: 1600px) {
    width: 33px;
    height: 90px;
    margin-top: 12px;
    left: -33px;
  }
}

.stepIndicator {
  transform: rotate(-90deg);
  color: #ffffff;
  display: flex;
  font-size: clamp(12px, 0.833vw, 22px);
  font-weight: 400;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 46px;

  @media (min-width: 1200px) and (max-width: 1600px) {
    margin-top: 37px;
  }
}

.eachStep {
  align-self: center;
  border-radius: 6px;
  position: relative;
}

.eachStep1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  margin: 0px 20vw 12px 20vw;
  background: #ffffff;
  padding: 0rem 1rem;
  height: 4.115vw;
  box-sizing: border-box;
  border-radius: 6px;
  border: 1px solid #d3dae3;
  overflow-x: auto;

  /* Add TABLE_SCROLL_BAR styles here */
  @media (max-width: 1365px) {
    margin: 10px 15vw;
  }
}

.stepsScrollContainer {
  height: calc(100vh - 12rem);
  overflow: auto;
  /* Add TABLE_SCROLL_BAR styles here */
}

.stepStats {
  color: #0f2f51;
  font-weight: 500;
  font-size: 18px;
}

.displayStats {
  display: flex;
  align-items: center;
}

.commonStats {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 2rem;

  @media (max-width: 1470px) {
    padding-left: 1rem;
  }
}

.commonSlash {
  padding-left: 2rem;
  color: #d3dae3;

  @media (max-width: 1470px) {
    padding-left: 1rem;
  }
}

.commonSlash1 {
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  color: #d3dae3;

  @media (max-width: 1470px) {
    padding-left: 0.4rem;
  }
}

.commonCountStats {
  color: #0f2f51;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.commonText {
  color: #626b7e;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.stepTitle {
  font-size: clamp(16px, 0.938vw, 30px);
  color: #212833;
  font-weight: 500;
}

.stepTitle1 {
  font-size: clamp(14px, 0.833vw, 22px);
  color: #626b7e;
  font-weight: 500;
}

.infoContainerTitle {
  font-size: clamp(14px, 0.833vw, 30px);
  color: #000000;
  font-weight: 500;
  line-height: 19px;
}

.activityLogMonthTitle {
  font-size: clamp(14px, 0.833vw, 30px);
  color: #212833;
  margin: auto 0 !important;
  padding: 0px 8px;
  font-weight: 500;
}

.stepHead {
  display: flex;
  justify-content: space-between;
  background: #fff3e2;
  border-radius: 4px 4px 0 0;
  padding: 20px;

  @media (min-width: 1200px) and (max-width: 1600px) {
    padding: 10px;
  }
}

.flex {
  display: flex;
  gap: 8px;
  width: -webkit-fill-available;
}

.dateTimeZoneContainer {
  display: grid;
  width: -webkit-fill-available;
  grid-template-columns: 1fr 1fr 1fr;
  background: #f6f6f6;
  padding: 12px;
  border: 1px solid #e6e6e6;
  border-radius: 6px 6px 0 0;
}

.datesContainer {
  border: 1px solid #e6e6e6;
  border-top: none;
  border-radius: 0 0 6px 6px;
}

.stepLeftFooter {
  display: flex;
  align-items: center;
}

.flexJustifyBetween {
  display: flex;
  justify-content: space-between;
}

.eachStepAction {
  display: flex;
  cursor: pointer;
}

.eachStepAction1 {
  display: flex;
  cursor: no-drop;
}

.actionText {
  color: #626b7e;
  font-weight: 400;
  font-size: clamp(12px, 0.729vw, 30px);
  align-self: center;
  padding-right: 20px;
  white-space: nowrap;
  cursor: pointer;
  line-height: 19px;

  @media (min-width: 1200px) and (max-width: 1600px) {
    padding-right: 15px;
  }
}

.actionText2 {
  color: #626b7e;
  font-weight: 400;
  font-size: clamp(12px, 0.729vw, 30px);
  align-self: center;
  padding-right: 30px;
  white-space: nowrap;
  text-align: center;
  line-height: 19px;

  @media (min-width: 1200px) and (max-width: 1600px) {
    padding-right: 15px;
  }
}

.bxSha {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
}

.actionText1 {
  color: #626b7e;
  font-weight: 400;
  font-size: clamp(12px, 0.729vw, 30px);
  align-self: center;
  padding-right: 20px;
  white-space: nowrap;
  cursor: no-drop;
  line-height: 19px;

  @media (min-width: 1200px) and (max-width: 1600px) {
    padding-right: 15px;
  }
}

.dateTimeText {
  color: #626b7e;
  font-weight: 400;
  font-size: clamp(10px, 0.625vw, 20px);
  align-self: center;
  white-space: nowrap;
  cursor: pointer;
}

.stepBody {
  background: white;
  padding: 20px;
  border-bottom: none;

  @media (min-width: 1200px) and (max-width: 1600px) {
    padding: 10px;
  }
}

.eachStepDetailKey {
  color: #626b7e;
  font-weight: 500;
  font-size: clamp(12px, 0.729vw, 30px);
  padding-right: 4px;
  cursor: pointer;
}

.eachStepDetailKey1 {
  color: #626b7e;
  font-weight: 500;
  font-size: clamp(12px, 0.729vw, 30px);
  padding-right: 4px;
  cursor: no-drop;
}

.eachStepDetail {
  display: flex;
  align-items: center;
}

.eachStepDetailValue {
  color: #626b7e;
  font-weight: 400;
  font-size: clamp(12px, 0.729vw, 30px);
  align-self: center;
}

.flexAlignCenter {
  display: flex;
  justify-content: center;
}

.stepFoolter {
  padding: 20px;
  border-top: 1px solid #d3dae3;
  border: none;
  background: white;
  border-radius: 0 0 4px 4px;
  display: flex;
  justify-content: space-between;

  @media (min-width: 1200px) and (max-width: 1600px) {
    padding: 10px;
  }
}

.stepSpace {
  height: 70px;
  border-right: 1px solid #d3dae3;
  width: 5vw;
  position: relative;

  @media (min-width: 2000px) {
    height: 100px;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    height: 55px;
  }
}

.stepSpace1 {
  height: 50px;
  border-right: 1px solid #d3dae3;
  width: 5vw;
  position: relative;

  @media (min-width: 2000px) {
    height: 100px;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    height: 35px;
  }
}

.addStepBtn {
  position: absolute;
  top: 1rem;
  left: 0.5vw;
  width: 9vw;
  background: white;
  border: none;
  padding: 10px;
  border-radius: 25px;
  box-shadow: 0px 3px 6px #00000014;
  color: #626b7e;
  font-size: clamp(14px, 0.833vw, 30px);
  font-weight: 500;
  cursor: pointer;
  min-width: 6rem;
  white-space: nowrap;

  @media (min-width: 2000px) {
    top: 1.5rem;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    top: 0.8rem;
    padding: 5px;
  }
}

.stepsTitle {
  text-align: center;
  padding-top: 1rem;
}

.stepsContent {
  text-align: center;
  color: #626b7e;
  width: 40vw;
  align-self: center;
  padding: 1rem;
}

/* Campaign Styles */
.CampaignContainer {
  margin-left: 20px;
  margin-top: 21px;
}

.campaignListTableContainer {
  height: calc(100vh - 64px);
  margin: 16px 20px 0px 20px;

  @media (min-width: 1200px) and (max-width: 1600px) {}
}

.campaignListTableFolderDetailContainer {
  height: calc(100% - 64px);
  margin: 16px 20px 0px 20px;
}

.tableContainer {
  height: calc(100vh - 90px);
}

.searchAndTableContainer {
  height: calc(100vh - 160px);
  margin: 0px 16px;
}

.leftContainer {
  width: 220px;
}

.menuStyle {
  margin-top: 4px;
  border-radius: 12px !important;
}

.redButton {
  background: white;
  border: none;
  color: #ff4848;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.innerleftContainer {
  background: white;
  padding: 0.5rem 0rem 1rem 0rem;
}

.eachStatus {
  padding: 1rem 0.5rem 0 0.5rem;
  display: flex;
  justify-content: space-between;
}

.containSearchIcon {
  background: white;
  border: 1px solid #d3dae3;
  align-items: center;
  border-right: 0;
  display: flex;
  border-radius: 6px 0 0 6px;
  padding: 0px 5px 0px 10px;
  justify-content: center;
}

.icon {
  color: #626b7e;
  font-size: 14px;
  height: 16px;
}

.eachAction {
  display: flex;
  align-items: center;
  background: white;
  padding: 0.75rem 1rem;
  margin-right: 0.75rem;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #d3dae3;
}

.searchCampaignIcon {
  display: flex;
  align-items: center;
  height: 35px;
  width: 400px;
  background-color: #fff;
  border: 1px solid #d3dae3;
  border-radius: 5px;
}

.containCampaignActions {
  display: flex;
  justify-content: space-between;
}

.searchContainer {
  display: flex;
  padding: 0.8rem;
  height: 100%;
  align-items: center;
  background: white;
  margin: 0 1rem;
  width: 85%;
}

.searchBox {
  height: 100%;
  border: none;
  color: #626b7e;
  font-weight: 400;
  margin-left: 0.4rem;
  width: 100%;
}

.containTable {
  margin-top: 20px;
  height: calc(100% - 61px);

  @media (min-width: 1200px) and (max-width: 1600px) {
    margin-top: 10px;
  }
}

.modalFooterRightData {
  display: flex;
  gap: 8px;
}

.containTableInsideFolder {
  margin-top: 20px;
  height: calc(100% - 43px);

  @media (min-width: 1200px) and (max-width: 1600px) {
    margin-top: 10px;
    height: calc(100% - 61px);
  }
}

.infoContainer {
  background: white;
  margin: 0px 16vw;
  border-radius: 6px;
}

.scheduleContainer {
  background: white;
  margin: 20px 16vw;
  border-radius: 6px;
}

.borderBox {
  border-bottom: 1px solid #e6e6e6;
  padding: 1rem;
}

.input {
  color: #626b7e !important;
  border: 1px solid #d3dae3;
  border-radius: 4px;
  padding: 12px;
  width: 100%;
  font-size: clamp(12px, 0.729vw, 30px);
}

.infoLabel {
  color: #212833;
  font-size: clamp(12px, 0.729vw, 30px);
  font-weight: 400;
  padding-bottom: 10px;

  @media (min-width: 1200px) and (max-width: 1600px) {
    padding-bottom: 10px;
  }
}

.eachInputContainer {
  width: 100%;
}

.eachInputContainer1 {
  padding: 1rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.fieldContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.fieldContainer1 {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-top: 1rem;
}

.containEditor {
  border: 1px solid #d3dae3;
  min-height: 15rem;
  border-radius: 6px;
}

.grid2fr1fr {
  display: grid;
  grid-template-columns: 2fr 1.1fr;
  padding-right: 1rem;
}

.containInputs {
  padding: 1rem;
  border-bottom: 1px solid #e6e6e6;
}

.containInput {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.containDates {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0;
}

.containDates2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0.5rem;
}

.containEach {
  display: flex;
  gap: 6px;
  align-items: center;
}

.containEachInfo {
  padding: 1rem 1rem 0rem 0;
}

.eachDay {
  text-transform: capitalize;
}

.timeSelection {
  margin-top: 1rem;
  border-radius: 4px;
}

.containDate {
  width: 10vw;
}

.subHeading {
  font-size: clamp(12px, 0.729vw, 30px);
  font-weight: 500;
  color: #626b7e;
  padding: 0px 16px 0px 10px;
  width: 13vw;
}

/* Styles.module.css */

.containerDateHeaders {
  padding: 0 1rem 1rem 1rem;
  display: flex;
  border-bottom: 1px solid #d3dae3;
}

.updateBtn {
  background: #ff4848;
  border: none;
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  font-size: clamp(12px, 0.729vw, 30px);
}

.activityContainer {
  height: calc(100% - 9.5rem);
  margin-top: 1rem;
  overflow: auto;
}

.eachActivity {
  background: white;
  padding: 1rem;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  width: 50vw;
}

.eachActivity1 {
  width: 50vw;
}

.containerIcon {
  background: #c4c4c4;
  display: flex;
  align-items: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  justify-content: center;
}

.statsContainer {
  background: white;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.07);
  padding: 8px 16px 8px 16px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  overflow: auto;
  width: 52vw;
  border: 1px solid #DBDBDB;

}

.statsParentContainer {
  margin: 1rem 0;
  display: flex;
  justify-content: center;
}

.eachActivityIcon {
  width: 3rem;
  height: 3rem;
}

.pureFlex {
  display: flex;
  cursor: pointer;
}

.pureFlex2 {
  display: flex;
}

.pureFlex1 {
  display: flex;
  cursor: no-drop;
}

.settingUpdateBtn {
  padding: 1rem;
  width: fit-content;
}

.statsTitle {
  align-self: center;
}

.eachStat {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
  border-right: 1px solid #dbdbdb;
}

.lastStat {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
  padding-right: 0;
}

.bodyContainer {
  padding-top: 1rem;
}

.eachContactStat {
  background: white;
  padding: 1rem;
  border-radius: 12px;
  box-shadow: 0px 2px 3px rgba(33, 40, 51, 0.12);
  width: 14.792vw;
  cursor: pointer;
}

.containAllStats {
  display: flex;
  column-gap: 1rem;
  overflow: auto;
  padding: 1rem;
  padding-bottom: 2px;
}

.statTitle {
  color: #0f2f51;
  font-size: clamp(1rem, 1vw, 2rem);
  font-weight: 600;
  padding: 2px;
}

.searchInput {
  color: #626b7e;
  padding: 10px 16px 10px 0px;
  border: 1px solid #d3dae3;
  width: 100vw;
  border-left: none;
  border-radius: 0 6px 6px 0;
  font-size: clamp(12px, 0.729vw, 30px);
}

.containSearch {
  margin: 0px 26px;
}

.image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 1rem;
}

.avatarContainer {
  display: flex;
  align-items: center;
}

.justifyBetween {
  display: flex;
  justify-content: space-between;
}

.alignSelfCenter {
  align-self: center;
  padding-left: 12px;
}

.contactsContainer {
  margin: 0px 1rem;
  height: calc(100% - 70px);
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}

.contactsRootContainer {
  height: calc(100vh - 266px);
  margin-top: 1rem;
  border-radius: 12px;
}

.containEachContact {
  background: white;
  border-bottom: 1px solid #d3dae3;
}

.eachContact {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.eachContactStepActive {
  display: flex;
  border: 1px solid #d3dae3;
  padding: 0.5rem 0.8rem;
  border-radius: 6px;
  background: #f7f9fb;
  margin-right: 20px;
  width: 160px;
}

.eachContactStepSent {
  display: flex;
  border: 1px solid #2080f6;
  padding: 0.5rem 0.8rem;
  border-radius: 6px;
  background: #ebf1fd;
  margin-right: 20px;
  width: 160px;
}

.eachContactStepBounce {
  display: flex;
  border: 1px solid #e73f3f;
  padding: 0.5rem 0.8rem;
  border-radius: 6px;
  background: #fceaea;
  margin-right: 20px;
  width: 160px;
}

.eachContactStepLead {
  display: flex;
  border: 1px solid #01a63e;
  padding: 0.5rem 0.8rem;
  border-radius: 6px;
  background: #e8f6ed;
  margin-right: 20px;
  width: 160px;
}

.eachContactStepDeliver {
  display: flex;
  border: 1px solid #3405e1;
  padding: 0.5rem 0.8rem;
  border-radius: 6px;
  background: #f6f3ff;
  margin-right: 20px;
  width: 160px;
}

.eachContactStepUnsubscribe {
  display: flex;
  border: 1px solid #415A77;
  padding: 0.5rem 0.8rem;
  border-radius: 6px;
  background: #f6f3ff;
  margin-right: 20px;
  width: 160px;
}

.eachContactTitle {
  color: #626b7e;
  font-weight: 600;
  font-size: clamp(12px, 0.729vw, 30px);
}

.containStepList {
  display: flex;
  overflow: auto;
  padding: 1rem;
  padding-top: 0;
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
}

.eachFolder {
  display: flex;
  border-bottom: 1px solid #d3dae3;
  padding: 0.65rem 0.5rem;
  cursor: pointer;
}

.commonClass {
  width: 12.031vw;
  display: grid;
  background: #fff;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  padding: 20px;
  margin-left: 20px;
}

.folderHeader {
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
}

.folderButton {
  display: flex;
  align-items: center;
  margin-right: 30px;
  margin-left: 10px;
  border: 1px solid #d3dae3;
  background: #ffff;
  height: 35px;
  border-radius: 5px;
  color: #626b7e;
  cursor: pointer;
}

.showFolder {
  margin-top: 10px;
  display: flex;
  overflow-x: auto;
}

.modalEditClone {
  display: flex;
  align-items: center;
  width: 50%;
  margin: auto;
  background-color: white;
  margin-top: 50px;
}

.showFolderContainer {
  background: #fff;
  width: 180px;
  height: 158px;
  margin-left: 30px;
  border-radius: 10px;
}

.showFolderContainer1 {
  background: #d3dae3;
  width: 231px;
  height: 193px;
  margin-left: 12px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.07);
}

.folderOptions {
  display: flex;
  justify-content: space-between;
}

.folderIcon {
  color: #ffc87f;
  margin-left: 12px;
  margin-top: 18px;
}

.folderIcon1 {
  color: #626b7e;
  margin-left: 12px;
  margin-top: 18px;
}

.vertIcon {
  color: #626b7e;
  margin-top: 17px;
  margin-right: 10px;
  cursor: pointer;
}

.folderName {
  display: flex;
  flex-direction: column;
}

.nameStyle {
  margin-top: 10px;
  margin-left: 12px;
  font-weight: 600;
  color: #0f2f51;
  font-size: 14px;
  cursor: pointer;
}

.nameStyle1 {
  margin-top: 5px;
  margin-left: 12px;
  color: #626b7e;
  font-size: 12px;
  cursor: pointer;
}

.endPart {
  margin-top: 10px;
  border-top: 1px solid #d3dae3;
  display: flex;
  align-items: center;
}

.imageSet {
  height: 25px;
  width: 25px;
  border: 1px solid #d3dae3;
  border-radius: 50px;
  margin-left: 12px;
  margin-top: 6px;
}

.spanStyle {
  margin-left: 3px;
  color: #626b7e;
  margin-top: 6px;
  font-size: 12px;
  white-space: nowrap;
}

.viewAll {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.imageView {
  height: 42px;
  width: 42px;
}

.viewAllText {
  height: 19px;
  color: #212833;
  font-size: 16px;
  font-weight: 600;
  margin-top: 8px;
}

.dialog {
  position: fixed;
  top: 0;
  right: 0;
  margin-left: 50vw;
}

.listFolder {
  display: flex;
  justify-content: space-between;
  margin-left: 20px;
}

.spanSize {
  font-size: clamp(12px, 0.729vw, 30px);
}

.listFolderButton {
  display: flex;
  align-items: center;
  margin-right: 20px;
  margin-left: 10px;
  border: 1px solid #d3dae3;
  background: #fff;
  height: 40px;
  border-radius: 5px;
  color: #626b7e;
  cursor: pointer;
}

.templateHeadContainner {
  margin-left: 20px;
  margin-top: 15px;

  @media (max-width: 1700px) {
    margin-top: 8px;
  }
}

.templateHeadContainner1 {
  margin-left: 20px;
  margin-top: 15px;

  @media (max-width: 1700px) {
    margin-top: 8px;
  }
}

.templateSearchContainer {
  display: flex;
  justify-content: space-between;
  margin: 1rem;
  margin-bottom: 0;
  background: #ffffff;
  border-radius: 6px 6px 0 0;
}

.rightDisplay {
  display: flex;
  padding: 16px;
  gap: 8px;
}

.templateSearchLeft {
  display: flex;
  padding: 16px;
  gap: 8px;
  border-radius: 6px;
}

.templateSearchIcon {
  display: flex;
  align-items: center;
  height: 35px;
  width: 400px;
  background-color: #fff;
  border: 1px solid #d3dae3;
  border-radius: 5px;
}

.templateMove {
  display: flex;
  align-items: center;
  width: 120px;
  height: 40px;
  margin-left: 8px;
  background-color: white;
  border: 1px solid #d3dae3;
  border-radius: 5px;
  cursor: pointer;

  @media (max-width: 1700px) {
    height: 32px;
    margin-left: 5px;
    width: 100px;
  }
}

.templateMove5 {
  display: flex;
  align-items: center;
  width: 120px;
  height: 40px;
  margin-left: 8px;
  background-color: white;
  border: 1px solid #d3dae3;
  border-radius: 5px;
  cursor: pointer;
  opacity: 50%;

  @media (max-width: 1700px) {
    height: 32px;
    margin-left: 5px;
    width: 100px;
  }
}

.templateMove6 {
  display: flex;
  align-items: center;
  width: 180px;
  height: 40px;
  margin-left: 8px;
  background-color: white;
  border: 1px solid #d3dae3;
  border-radius: 5px;
  cursor: pointer;
  opacity: 50%;

  @media (max-width: 1700px) {
    height: 32px;
    margin-left: 5px;
    width: 160px;
  }
}

.templateMove1 {
  display: flex;
  align-items: center;
  height: 40px;
  width: 180px;
  margin-left: 8px;
  background-color: white;
  border: 1px solid #d3dae3;
  border-radius: 5px;
  cursor: pointer;

  @media (max-width: 1700px) {
    height: 32px;
    width: 160px;
    margin-left: 5px;
  }
}

.templateMove2 {
  display: flex;
  align-items: center;
  height: 40px;
  width: 120px;
  margin-right: 20px;
  background-color: white;
  border: 1px solid #d3dae3;
  border-radius: 5px;
  cursor: pointer;

  @media (max-width: 1700px) {
    height: 32px;
    width: 100px;
    margin-left: 5px;
  }
}

.campaignLabelIconContainer {
  display: flex;
  align-items: center;
  margin-left: 10px;
  background-color: white;
  border: 1px solid #d3dae3;
  border-radius: 5px;
  cursor: pointer;
  column-gap: 5px;

  padding: 0px 12px;
}

.templateMove:hover,
.templateMove1:hover,
.templateMove2:hover,
.templateMove5:hover,
.campaignLabelIconContainer:hover,
.templateMove6:hover {
  background-color: #d3dae3;
}

.campaignLabelIconContainer1 {
  display: flex;
  align-items: center;
  margin-left: 10px;
  background-color: white;
  border: 1px solid #d3dae3;
  border-radius: 5px;
  cursor: pointer;
  column-gap: 5px;
  opacity: 60%;
  padding: 0px 12px;
}

.campaignLabelIconBtnContainer {
  display: flex;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  padding: 0px 12px;
}

.campaignLabelIcon {
  color: #626b7e;
  font-size: 14px;
  height: 16px;
}

.templateContent {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  height: 40px;
  width: 100%;
  border: none;
  background: none;
  cursor: pointer;
  column-gap: 5px;

  @media (max-width: 1700px) {
    height: 32px;
  }
}

.templateAction {
  font-size: clamp(12px, 0.729vw, 30px);
  color: #626b7e;
}

.templateTableContent {
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;

  @media (max-width: 1700px) {
    margin-top: 6px;
  }
}

.dataBox {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
}

.dataEdit {
  display: flex;
  border: 0.5px solid #d3dae3;
  height: 40px;
  width: 330px;
  border-radius: 5px;
  margin-top: 5px;
}

.dataData {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
}

.spanInBtn {
  font-size: 14px;
  font-weight: 600;
}

.createBtn {
  background-color: #ff4848;
  border-radius: 5px;
  margin-top: 20px;
  border: 1px solid #d3dae3;
  height: 40px;
  color: #ffffff;
  font-size: 10px;
  width: 120px;
}

.containGmailBtn {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gImmg {
  height: 5.885vw;
  width: 5.885vw;
}

.gBox {
  width: 33.646vw;
  height: 19.479vw;
  background: #ffffff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 4px;
}

.gText {
  color: #0f2f51;
  font-weight: 700;
  font-size: clamp(20px, 1.25vw, 30px);
  line-height: 29px;
  margin-top: 5px;
}

.gText1 {
  color: #626b7e;
  font-weight: 400;
  font-size: clamp(14px, 0.833vw, 18px);
  line-height: 21px;
}

.containEachInput {
  margin: 1rem;
  width: 45%;
}

.containAllInputs {
  display: flex;
  flex-wrap: wrap;
  height: calc(100vh - 8.5rem);
  overflow: auto;
  /* Add TABLE_SCROLL_BAR styles here */
}

.showFolderPath {
  margin-left: 30px;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.textStyling {
  color: #0f2f51;
  opacity: 50%;
  font-size: 20px;
  cursor: pointer;
}

.folderStyling {
  color: #0f2f51;
  font-size: 20px;
  margin-left: 10px;
}

.noFolder {
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.circLoad {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.circLoad1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70%;
}

.skelData {
  width: 10%;
  height: 20%;
}

.movingHeader {
  font-weight: 600;
  font-size: clamp(14px, 0.833vw, 22px);
  color: #0f2f51;
  border-bottom: 1px solid #d3dae3;
}

.movingContainer {
  border-radius: 15px !important;
}

.movMenu {
  margin-top: 4px;
  border-radius: 12px !important;
}

.centerDesign {
  display: flex;
  justify-content: center;
  align-items: center;
}

.movingContent {
  padding-left: 12px;
  display: flex;
  align-items: center;
  height: 35px;
  cursor: pointer;
  gap: 4px;
}

.activeMovingContent {
  padding-left: 12px;
  display: flex;
  align-items: center;
  height: 35px;
  cursor: pointer;
  background: #ffb6b621 !important;
  gap: 4px;
}

.movingContent:hover,
.activeMovingContent:hover {
  background-color: #f8f8f8;
  box-shadow: inset 2px 0px 0px 0px #ff4848;
}

.movingContent:focus,
.activeMovingContent:focus {
  background-color: red;
}

.movingContent1 {
  display: flex;
  align-items: center;
  height: 35px;
  cursor: pointer;
  background: #f7f9fb;
}

.imgMov {
  padding-left: 12px;
}

.movFooter {
  border-top: 1px solid #e6e6e6;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.movDataCont {
  color: #626b7e;
  font-size: 14px;
  font-weight: 400;
}

.movDataCont1 {
  color: #0f2f51;
  font-size: 14px;
  font-weight: 500;
}

/* Styles.module.css */

.movCncl {
  margin-right: 5px;
  height: 32px;
  border-radius: 6px;
  width: 83px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  border: 1px solid #d3dae3;
  color: #626b7e;
  cursor: pointer;

  &:hover {
    color: #ff4848;
    border: 1px solid #ff4848;
  }
}

.crtBtn {
  margin-right: 7px;
  height: 32px;
  color: #ffffff;
  background: #ff4848;
  border-radius: 6px;
  width: 83px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  cursor: pointer;
}

.crtBtn:hover {
  background: #0f2f51;
}

.movBtnGen {
  display: flex;
  gap: 8px;
}

.searchIcon {
  display: flex;
  align-items: center;
  height: 18px !important;
  width: 18px !important;
  background-color: #fff;
  border-radius: 5px;
  column-gap: 5px;
  color: #626b7e;
  font-size: clamp(12px, 0.729vw, 30px) !important;
}

.settingsBodyContainer {
  height: calc(100vh - 9.5rem);
  overflow: auto;
  margin-top: 1rem;
}

/* Folder Management Styles */
.folderRootContainer {
  background: #f7f9fb;
  height: 18.5rem;

  @media (min-width: 1200px) and (max-width: 1600px) {
    height: 12.5rem;
  }
}

.folderDetailRootContainer {
  height: 64px;
  background: #f7f9fb;
  box-shadow: 0px 2px 6px rgb(0 0 0 / 7%);

  @media (min-width: 1200px) and (max-width: 1600px) {
    height: 56px;
  }
}

.folderContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;

  @media (min-width: 1200px) and (max-width: 1600px) {
    padding: 10px 20px;
  }
}

.tempFolderHd {
  color: #0f2f51;
  font-weight: 600;
  font-size: clamp(14px, 1.042vw, 36px);
  line-height: 24px;
}

.folderRightSide {
  display: flex;
  grid-gap: 10px;
}

.searchIconContainer {
  display: flex;
  align-items: center;
  height: 40px;
  width: 400px;
  background-color: #fff;
  border-radius: 5px;
  column-gap: 5px;
  border: 1px solid #d3dae3;

  @media (max-width: 1700px) {
    height: 32px;
    width: 250px;
  }
}

.folderManagementSearchIcon {
  font-size: medium !important;
  margin-left: 8px !important;
  color: #626b7e;
}

.inputBase {
  font-size: clamp(12px, 0.729vw, 20px) !important;
  color: #626b7e !important;
  width: 355px;

  &.MuiInputBase-input {
    color: red;
  }

  @media (max-width: 1700px) {
    width: 210px;
  }
}

.inputColor {
  color: #626b7e !important;
  font-family: "Inter", sans-serif !important;
}

.tempFolderButton {
  display: flex;
  align-items: center;
  grid-gap: 7px;
  border: 1px solid #d3dae3;
  background: #fff;
  height: 40px;
  border-radius: 5px;
  color: #626b7e;
  cursor: pointer;

  padding: 0px 15px;

  @media (max-width: 1700px) {
    height: 32px;
  }
}

.tempFolderButton:hover {
  background-color: #d3dae3;
}

.newFolderTextStyle {
  font-size: clamp(12px, 0.729vw, 30px);
}

.folderManagementLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 158px;
}

/* Other Styles */

.timingZoneHeaderTitle {
  font-size: clamp(12px, 0.729vw, 30px);
  font-weight: 500;
  color: #626b7e;
  width: 13vw;

  @media (min-width: 1200px) and (max-width: 1600px) {
    width: 13vw;
  }
}

.contactUsernameText {
  font-size: clamp(14px, 0.833vw, 30px);
  font-weight: 600;
  color: #212833;
}

.activityLogTimeString {
  color: #626b7e;
  font-weight: 400;
  font-size: clamp(12px, 0.729vw, 30px);
  align-self: center;
  padding-right: 0px;
  white-space: nowrap;
  cursor: pointer;
}

.activityLogTimeContainer {
  display: flex;
  align-items: center;
  width: -webkit-fill-available;
  padding-left: 30px;
  column-gap: 5px;
  align-self: center;
}

.activityLogDateContainer {
  display: flex;
  align-items: center;
  column-gap: 5px;
  width: -webkit-fill-available;
  align-self: center;
}

.selectDateTimeZoneContainer {
  display: grid;
  column-gap: 1rem;
  grid-template-columns: 1fr 1fr;
}

.inputHeight {
  height: 2.8rem;
}

.menuBox {
  padding-left: 18px;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 21.875vw;
  color: #0f2f51;
  font-weight: 600;
  line-height: 19px;
  font-size: clamp(14px, 0.833vw, 18px);
  border-bottom: 1px solid #d3dae3;
}

.bodyNoFol {
  padding-left: 18px;
  padding-top: 18px;
  font-weight: 500;
  font-size: clamp(12px, 0.729vw, 16px);
  line-height: 17px;
  color: #626b7e;
}

.noFolBtn {
  border: none;
  float: right;
  margin-top: 24px;
  margin-right: 18px;
  margin-bottom: 18px;
  height: 37px;
  color: #ffffff;
  background: #ff4848;
  border-radius: 6px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  cursor: pointer;
}

.noFolBtn:hover {
  background: #0f2f51;
}

.btnnext {
  background: none;
  border: 0;
  color: #ff4848;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  opacity: 0.5;
}

.commonWidth {
  width: 21.615vw;
}

.commonWidthContact {
  width: 28.021vw;
}

.moveStyle {
  padding: 12px;
}

.moveStyle1 {
  padding: 12px;
  width: 100%;
}

.moveDataHeight {
  max-height: 200px;
  overflow: auto;
  min-height: 100px;
  /* Add TABLE_SCROLL_BAR styles here */
}

.mTop {
  margin-top: 5px;
}

.loaderStyle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: #f7f9fb;
}

.tableStyle {
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(24, 24, 28, 0.06),
    0px 4px 4px -4px rgba(24, 24, 28, 0.02);
  border-radius: 0 0 6px 6px;
}

.backGround {
  background: white;
}

.moveDataHeight::-webkit-scrollbar .settingsBodyContainer::-webkit-scrollbar .containAllInputs::-webkit-scrollbar .stepsScrollContainer::-webkit-scrollbar .eachStep1::-webkit-scrollbar .activities::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.moveDataHeight::-webkit-scrollbar-track .settingsBodyContainer::-webkit-scrollbar-track .containAllInputs::-webkit-scrollbar-track .stepsScrollContainer::-webkit-scrollbar-track .eachStep1::-webkit-scrollbar-track .activities::-webkit-scrollbar-track {
  height: 5px;
}

.moveDataHeight::-webkit-scrollbar-thumb .settingsBodyContainer::-webkit-scrollbar-thumb .containAllInputs::-webkit-scrollbar-thumb .stepsScrollContainer::-webkit-scrollbar-thumb .eachStep1::-webkit-scrollbar-thumb .activities::-webkit-scrollbar-thumb {
  background: #626b7e;
  border-radius: 10px;
}


.borderRad {
  border-radius: 6px;
  padding-top: 12px;
  box-shadow: 0px 0px 16px rgba(24, 24, 28, 0.12),
    0px 12px 8px rgba(24, 24, 28, 0.02);

}

.borderBottomFix {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  box-shadow: inset -1px 0 0 #efefef, inset 0 -1px 0 #efefef,
    inset 1px 0 0 #efefef, inset 0 1px 0 #efefef;
  border-bottom: 1px solid #e6e6e6;
  padding: 12px 0px 12px 12px;
}

.filterBody {
  padding: 0px 0px 12px 0px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  height: 180px !important;
}

.widthData {
  padding-bottom: 20px;
}

.listTenant {
  display: flex;
  flex-direction: column;
  /* gap: 12px; */
}

.skeletonPadding {
  padding: 8px 14px;
}

.displayOwnerNoDataData {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6.25px;
  cursor: pointer;
  padding: 8px 12px;
  height: 100%;
}

.displayOwnerData {
  display: flex;
  align-items: center;
  gap: 6.25px;
  cursor: pointer;
  padding: 8px 12px;
}

.displayOwnerData:hover {
  background-color: #F8F8F8;
  box-shadow: #FF4848;
}

.filterFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  gap: 8px;
  padding: 12px 12px 12px 0;
  border-top: 1px solid #E6E6E6;
}

.noRecordFoundImage {
  height: 20vh
}

.noRecordFoundContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  align-items: center;
  border-radius: 0px 0px 6px 6px;
}