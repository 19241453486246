.textCell:hover {
    color: #222124 !important;
    background-color: #f8f8f8 !important;
}

.textCell #copiedTextContainer {
    display: none;
}

.textCell:hover #copiedTextContainer {
    display: flex;
    cursor: pointer;
}

.boldTextCell:hover {
    color: #222124 !important;
    background-color: #f8f8f8 !important;
}

.boldTextCell #copiedTextContainer {
    display: none;
}

.boldTextCell:hover #copiedTextContainer {
    display: flex;
    cursor: pointer;
}

.userInfoCell:hover {
    color: #222124 !important;
    background-color: #f8f8f8 !important;
}

.userInfoCell #copiedTextContainer {
    display: none;
}

.userInfoCell:hover #copiedTextContainer {
    display: flex;
    cursor: pointer;
}

.companyNameLink:hover {
    color: #222124 !important;
    background-color: #f8f8f8 !important;
}

.companyNameLink #copiedTextContainer {
    display: none;
}

.companyNameLink:hover #copiedTextContainer {
    display: flex;
    cursor: pointer;
}

.emailCell:hover {
    color: #222124 !important;
    background-color: #f8f8f8 !important;
}

.emailCell #copiedTextContainer {
    display: none;
}

.emailCell:hover #copiedTextContainer {
    display: flex;
    cursor: pointer;
}

.marketingListCell:hover {
    color: #222124 !important;
    background-color: #f8f8f8 !important;
}

.marketingListCell #copiedTextContainer {
    display: none;
}

.marketingListCell:hover #copiedTextContainer {
    display: flex;
    cursor: pointer;
}

.textButtonCell:hover {
    color: #222124 !important;
    background-color: #f8f8f8 !important;
}

.textButtonCell #copiedTextContainer {
    display: none;
}

.textButtonCell:hover #copiedTextContainer {
    display: flex;
    cursor: pointer;
}

.statsDataCell:hover {
    color: #222124 !important;
    background-color: #f8f8f8 !important;
}

.statsDataCell #copiedTextContainer {
    display: none;
}

.statsDataCell:hover #copiedTextContainer {
    display: flex;
    cursor: pointer;
}

.buttonCell:hover {
    color: #222124 !important;
    background-color: #f8f8f8 !important;
}

.buttonCell #copiedTextContainer {
    display: none;
}

.buttonCell:hover #copiedTextContainer {
    display: flex;
    cursor: pointer;
}

.statusCell:hover {
    color: #222124 !important;
    background-color: #f8f8f8 !important;
}

.statusCell #copiedTextContainer {
    display: none;
}

.statusCell:hover #copiedTextContainer {
    display: flex;
    cursor: pointer;
}

.durationTextCell:hover {
    color: #222124 !important;
    background-color: #f8f8f8 !important;
}

.durationTextCell #copiedTextContainer {
    display: none;
}

.durationTextCell:hover #copiedTextContainer {
    display: flex;
    cursor: pointer;
}

.textURLCell:hover {
    color: #222124 !important;
    background-color: #f8f8f8 !important;
}

.textURLCell #copiedTextContainer {
    display: none;
}

.textURLCell:hover #copiedTextContainer {
    display: flex;
    cursor: pointer;
}

.taskListCell:hover {
    color: #222124 !important;
    background-color: #f8f8f8 !important;
}

.taskListCell #copiedTextContainer {
    display: none;
}

.taskListCell:hover #copiedTextContainer {
    display: flex;
    cursor: pointer;
}

.userDetailCell:hover {
    color: #222124 !important;
    background-color: #f8f8f8 !important;
}

.userDetailCell #copiedTextContainer {
    display: none;
}

.userDetailCell:hover #copiedTextContainer {
    display: flex;
    cursor: pointer;
}

.dueTimeStampCell:hover {
    color: #222124 !important;
    background-color: #f8f8f8 !important;
}

.dueTimeStampCell #copiedTextContainer {
    display: none;
}

.dueTimeStampCell:hover #copiedTextContainer {
    display: flex;
    cursor: pointer;
}

.leadStatusCell:hover {
    color: #222124 !important;
    background-color: #f8f8f8 !important;
}

.leadStatusCell #copiedTextContainer {
    display: none;
}

.leadStatusCell:hover #copiedTextContainer {
    display: flex;
    cursor: pointer;
}

.amountCell #copiedTextContainer {
    display: none;
}

.amountCell:hover #copiedTextContainer {
    display: flex;
    cursor: pointer;
}

.mainContainer:hover {
    border: 1px solid #FF4848 !important;
}

.defaultItem:hover {
    background-color: #f8f8f8 !important;
    box-shadow: inset 2px 0px 0px 0px #FF4848 !important;
}

.noRecordFoundImage {
    height: 20vh
}

.paperMenuClass1{
    width:15vw;
    box-shadow: 0px 12px 8px 0px rgba(24, 24, 28, 0.02), 0px 0px 16px 0px rgba(24, 24, 28, 0.12) !important;
  }