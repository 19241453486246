/* CSS for filterStripContainer */
.filterStripContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  gap: 8px;
  filter: drop-shadow(0px 3px 4px rgba(24, 24, 28, 0.04)) drop-shadow(0px 1px 1px rgba(24, 24, 28, 0.04));
}

/* CSS for filtersMainContainer */
.filtersMainContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 6px;
  width: 100%;
}

/* CSS for filterLabel */
.filterLabel {
  padding: 4px 10px;
}

/* CSS for addFilterButton */
.addFilterButton {
  padding-right: 6px;
}

/* CSS for filtersContainer */
.filtersContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 6px;
}

/* Your CSS Styles */

/* Form Input */
.inputForm {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* Input Label Field */
.inputLabelField {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

/* Dropdown Menu List */
.dropdownMenuList {
  width: 23.2vw;
}

/* Manage Card Container */
.manageCardContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #E6E6E6;
  padding: 8px 12px;
  border-radius: 6px;
  width: 100%;
}

.manageCardMainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  row-gap: 12px;
  height: calc(100% - 50px);
  overflow-y: auto;
  padding-right: 16px;
}

/* Selected Manage Card Container */
.selectedManageCardContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #E6E6E6;
  padding: 8px 12px;
  border-radius: 6px;
  background-color: #F8F8F8;
  width: 100%;
}

/* Add View Modal Body */
.addViewModalBody {
  display: flex;
  align-items: stretch;
  height: calc(70vh - 192px);
}

/* Add View Body Sub Container */
.addViewBodySubCntnr {
  border-right: 1px solid #E6E6E6;
  padding: 16px;
  width: 100%;
  height: calc(70vh - 195px);
}

/* View Label */
.viewLabel {
  padding-bottom: 12px;
}

/* Empty View Card */
.emptyViewCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

/* Empty View Card Body */
.emptyViewCardBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* View Name Container */
.viewNameContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
}

/* Create View Body */
.createViewBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
}

/* Create View Modal Footer */
.createViewModalFooter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

/* Created Date Menu Body */
.createdDateMenuBody {
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

/* Date Picker Container */
.datePickerContainer {
  display: flex;
  align-items: center;
  gap: 4px;
}

/* Created Date Button Apply */
.createdDateButtonApply {
  margin-left: auto;
}

/* Owner Assigned Menu Body */
.ownerAssignedMenuBody {
  padding: 12px;
}

/* Contact Owner List */
.contactOwnerList {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 10px;
}

.contactOwnerList:hover {
  background-color: #F8F8F8;
  box-shadow: inset 2px 0px 0px 0px #FF4848;
}

/* Label Container */
.labelContainer {
  cursor: pointer;
}

/* Icon Container */
.iconContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Icon */
.icon {
  cursor: pointer;
}

/* CSS file */

/* mainContainer styles */
.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  height: calc(100% - 280px);
  margin: 0px 16px 16px 16px;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 1px 2px rgba(24, 24, 28, 0.06), 0px 4px 4px -4px rgba(24, 24, 28, 0.02);
}

/* descriptionContainer styles */
.descriptionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

/* descriptionLabel styles */
.descriptionLabel {
  width: 15.104vw;
  text-align: center;
}

.seniorityContainer {
  min-width: 300px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: #ffffff;
  border-radius: 6px;
}

.filtrLabelContainer {
  padding: 8px 10px;
}

.filtrLabelContainer:hover {
  box-shadow: inset 2px 0px 0px 0px #FF4848;
  background-color: #f8f8f8;
}

.nameFilterContainer {
  display: flex;
  flex-direction: row;
  padding: 16px;
}

.jobTitleFilterContainer {
  display: flex;
  flex-direction: column;
}


.companyContainer {
  min-width: 450px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: #ffffff;
  border-radius: 6px;
}

.searchFilterContainer {
  padding: 0px 10px;
}

.industryFilterContainer {
  display: flex;
  flex-direction: column;
}

.normalFiltersContainer {
  display: flex;
  flex-direction: column;
}

.oppositeFiltersContainer {
  display: flex;
  flex-direction: column-reverse;
}

.containEachFilter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #E6E6E6;
  padding: 0.8rem;
  border-radius: 6px;
  width: 100%;
}

.containEachOpenFilter {
  display: flex;
  justify-content: space-between;
  border: 1px solid #d3dae3;
  padding: 0.8rem;
  border-radius: 3px 3px 0 0;
  width: 100%;
  align-items: center;
}

.accordionSummary {
  margin: 0 !important;
  padding: 0 !important;
}

.addFilter {
  display: flex;
  padding: 0px 1rem;
  align-items: center;
}

.filterCategoryLabel {
  margin-bottom: 8px;
}

.containCotactColumns {
  height: calc(100% - 64px);
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-right: 8px;
  overflow: auto;

}

.columnLabelContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.containColumnMainContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.allColumnsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.containColumns {
  display: flex;
  justify-content: space-between
}

.iconLabelContainer {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.containRight {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: #F8F8F8;
}

.draggableDiv {
  display: flex;
  flex-direction: row;
  padding: 8px;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.04), 0px 1px 1px rgba(24, 24, 28, 0.04);
  justify-content: space-between;
}

.draggableMainDiv {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: transparent;
}

.dragLabelContainer {
  display: flex;
  align-items: center;
  gap: 4px;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.crossIcon {
  display: flex;
  cursor: pointer;
}

.crossIconDisable {
  display: flex;
  cursor: not-allowed;
}

/* .modalBody {
  padding: 1rem;
} */

.editColumnModalFooterRightData {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.containHead {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.containLeft {
  margin-bottom: 0;
  border-radius: 6px;
  height: 56vh;
  padding: 16px;
}

.searchContainer {
  padding-bottom: 16px;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.containScroll {
  display: flex;
  flex-direction: column;
  height: 56vh;
  gap: 12px;
  padding-right: 8px;
}

.draggableDivLabel {
  display: flex;
  flex-direction: row;
  padding: 8px;
  gap: 6px;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.04), 0px 1px 1px rgba(24, 24, 28, 0.04);
}

.exportModalFooter {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
}

.flex1 {
  gap: 1rem;
  display: flex;
  justify-content: space-between;
}

.containEachAction {
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  padding: 8px;
  gap: 1rem;
  width: 12vw;
}

.tabHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  background-color: #ffffff;
  filter: drop-shadow(0px 3px 4px rgba(24, 24, 28, 0.04)) drop-shadow(0px 1px 1px rgba(24, 24, 28, 0.04));
}

/* Styles for the left side of the tab header container */
.tabHeaderLeftContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  padding: 16px 16px 0px 16px;
}

/* Styles for the right side of the tab header container */
.tabHeaderRightContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  padding: 0px 16px 0px 0px;
}

.sortingModal {
  background-color: #FFFFFF;
  border-radius: 6px;
}

.containSaveText {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  box-shadow: inset 0px -1px 0px 0px #E6E6E6;
}

.saveViewDisplayTxt {
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  box-shadow: inset 0px -1px 0px 0px #E6E6E6;
}

.saveViewDisplayTxt p {
  white-space: normal !important;
}

.containApplyBtn {
  display: flex;
  justify-content: space-between;
  padding: 16px;
}

.rightFooterSaveViewMenuButton {
  display: flex;
  gap: 8px;
  align-items: center;
}

.htmlEditor {
  /* box-shadow: inset -1px 0 0 #e6e6e6, inset 0 -1px 0 #e6e6e6, inset 1px 0 0 #e6e6e6, inset 0 1px 0 #e6e6e6; */
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  width: 100%;
}

.toolTipContainer {
  background: white;
  box-shadow: 0px 9px 66px #00000029;
  border-radius: 4px;
  z-index: 1;
}

.borderFlex {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-bottom: 1px solid #D3DAE3;
  cursor: pointer;
  gap: 4px;
}

.borderFlex span,
.borderFlex p {
  color: #FF4848 !important;
}

.noBorderFlex {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-bottom: 1px solid #D3DAE3;
  cursor: pointer;
  gap: 4px;
}

.noBorderFlex:hover,
.borderFlex:hover {
  box-shadow: inset 2px 0px 0px 0px #FF4848;
  background-color: #FFECEC;
}

.noBorderFlex span {
  color: #FF4848;
}

.pinnedViewActions {
  z-index: 1;
  background: white;
  box-shadow: 0px 9px 66px #00000029;
  border-radius: 0 0 6px 6px;
}

.borderFlex1 {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-bottom: 1px solid #D3DAE3;
  cursor: pointer;
  gap: 4px;
}

.borderFlex1:hover {
  box-shadow: inset 2px 0px 0px 0px #FF4848;
  background-color: #FFECEC;
}

.bodyEditViewContainerView {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.containInput {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.sharedWithBox {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.containRadioBtns {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.modalEditViewFooterRightData {
  display: flex;
  gap: 4px;
}

.viewDeleteModalRightFooter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
}


/* Styles for the main container of the table */
.tableMainContainer {
  margin: 0px 20px;
  background: #ffffff;
  box-shadow: 0px 24px 48px -8px rgba(24, 24, 28, 0.11), 0px 48px 64px -36px rgba(24, 24, 28, 0.13);
  height: calc(100% - 278px);
  border-radius: 0px 0px 6px 6px;
  /* ...SCROLL_BAR should be replaced with the actual SCROLL_BAR styles or variables */
}

.tableSelectedMainContainer {
  margin: 0px 20px;
  background: #ffffff;
  box-shadow: 0px 24px 48px -8px rgba(24, 24, 28, 0.11), 0px 48px 64px -36px rgba(24, 24, 28, 0.13);
  height: calc(100% - 320px);
  border-radius: 0px 0px 6px 6px;
  /* ...SCROLL_BAR should be replaced with the actual SCROLL_BAR styles or variables */
}

.addContactFormLoaderContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* Adjust height for small screens */
.HeadTable {
  height: 100%;
}

.containAllContacts {
  background: #FFE9E9;
  padding: 10px;
  display: flex;
  justify-content: center;
  gap: 2px;
  margin: 0px 16px;
}

.cursorPointer {
  cursor: pointer;
}

.importModalColumnsContainer {
  overflow: auto;
  background-color: #ffffff;
}

.csvMatchingTableHeaderContainer {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 2fr;
  background: #F6F6F6;
  border-radius: 6px 6px 0 0;
}

.csvMatchingTableHeader {
  padding: 0.75rem;
  border-right: 1px solid #E6E6E6;
}

.eachCloumn {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 2fr;
  box-shadow: inset -1.5px 0 1px -1px #E6E6E6, inset 1.5px 0 1px -1px #E6E6E6, inset 0 -1.5px 1px -1px #E6E6E6;
}

.containEachColumnName {
  display: flex;
  padding: 10px;
  border-radius: 6px;
  flex-direction: column;
  justify-content: center;
}

.dropdownContainer {
  padding: 10px;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  padding: 10px;
}

.modalFooterRightData {
  display: flex;
  gap: 8px;
  flex-direction: row;
}

.assignModalBody {
  display: flex;
  flex-direction: column;
  gap: 4px
}

.labelButtonContainer {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  width: 100%;
}

.labelButtonContainerAction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.importModalRightFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.halfwidth {
  width: 50%;
  padding-top: 16px;
}

.FLEXED {
  display: flex;
  width: 100%;
  gap: 16px;
}

.editorContent {
  border: 1px solid #e6e6e6;
}

.flexHead {
  display: flex;
  flex-direction: column;
  gap: 4px
}

.flexHeadTitle {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-bottom: 16px;
}

.flexHeadPad {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-top: 16px;

}

.descriptionPadding {
  padding-top: 16px;
}

.detailEmptyScreenMainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  height: 100%;
  width: 100%;
}

.emptyScreenImage {
  width: 100%;
  height: 100%;
  object-fit: contain
}

.emptyScreenImageMainContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border-radius: 50%;
  width: 120px;
  height: 120px;
}

.emptyScreenLabelContainer {
  width: 26.667vw;
  text-align: center;
}

.detailEmptyScreenButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.dateTimeFiledContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.inputLabelWidth {
  width: 100%;
}

.logEmailBody {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.accordionSummary {
  margin: 0 !important;
  padding: 0 !important;
}

.accrodionRoot {
  box-shadow: none;
  border: none;
  margin: 0;
  width: 100%;
  border-radius: 6px !important;
  box-shadow: 0px 1px 1px 0px rgba(24, 24, 28, 0.04), 0px 3px 4px 0px rgba(24, 24, 28, 0.04) !important;
}

.summaryContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  width: 100%;
}

.summaryContainerTask {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 16px 0px;
  width: 100%;
}

.summaryContainerMeeting {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.summaryContainerNote {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.summaryRightSection {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.imageTodoCall {
  width: 30px;
  height: 30px;
}

.dateTimeTaskContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
}

.HeadTaskContainer {
  width: 100%;

}

.HeadAttachmentContainer {
  width: 100%;

}

.summaryContainerHeads {
  border-radius: 6px;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 16px;
  background-color: #ffffff;
}

.summaryContainerHeadsOpen {
  background-color: #ffffff;
  border-radius: 6px;

  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 16px 16px 0px 16px;
}

.CallTodesignBorder {
  display: flex;
  gap: 8px;
  padding: 16px;
  border: 1px solid #E6E6E6;
  align-items: center;
  border-radius: 8px;
}

.CallTodesignBorderchangeOpen {
  background-color: #F8F8F8;
  display: flex;
  gap: 8px;
  padding: 10px 16px;
  border: 1px solid #E6E6E6;
  align-items: center;

  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom: 0px;
}

.iconStyle {
  display: flex;
  justify-content: end;
  color: #ffffff;
  cursor: pointer;
}

.CallTodesignBorderOpen {
  background-color: #F8F8F8;
  display: flex;
  gap: 8px;
  padding: 16px;
  ;
  border: 1px solid #E6E6E6;
  align-items: center;

  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom: 0px;
}

.taskDescriptionLabel {
  margin: 0px 16px 16px 16px;
  border: 1px solid #E6E6E6;
  /* padding: 16px; */
  border-radius: 6px;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
}

.meetingDescriptionLabel {
  margin: 16px;
  border: 1px solid #E6E6E6;
  /* padding: 16px; */
  border-radius: 6px;
  /* border-bottom-left-radius: 6px; */
}

.accordionSummaryIconContainer {
  margin-top: 2px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.emailLogAccordionSummaryLeftPart {
  display: flex;
  align-items: center;
  gap: 8px;
}

.loggedEmailTitleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.verticalLine {
  height: 16px;
  width: 1px;
  border-left: 1px solid #e6e6e6;
  margin: 0px 8px;
}

.loggedEmailTimeLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.emailLogAccordionSummaryRightPart {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.moreOptionsIconContianer {
  padding: 3px 3px 0px 3px;
}

.moreOptionsIconContianerban {
  padding: 3px 3px 0px 3px;
  cursor: no-drop;
}

.moreOptionsIconContianer:hover {
  background-color: #f8f8f8;
  border-radius: 6px;
}

.logEmailListSummaryUpperContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;
  padding: 16px;
  border-bottom: 1px solid #e6e6e6;
}

.logEmailListSummaryUpperMainContainer {
  margin: 16px;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
}

.logEmailListSummaryLowerContainer {
  padding: 16px;
  cursor: pointer;
}

.logEmailListSummaryLowerContainer:hover {
  background-color: #f8f8f8;
  border-radius: 0px 0px 6px 6px;
}

.HeadTaskContainerSubHead {
  padding: 0px 16px 16px 16px;
}

.taskDetailsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #E6E6E6;
  padding: 16px;
  gap: 20px;
}

.taskDetailsContainerSub {
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 4px;
}

.noteDetailsContainerSub {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.contactMenuFieldIconLable {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  width: fit-content;
  cursor: pointer;
}

.contactMenuFieldIconLable:hover p {
  color: #FF4848 !important;
}

.contactMenuFieldIconLable:hover span {
  color: #FF4848 !important;
}

.contactMenuFieldIconLable:hover {
  /* border-bottom: 1px solid #dbdbdb; */
  box-shadow: inset 0px -1px 0px 0px #dbdbdb;
}

.iconContainerMenuField {
  margin-top: 2px;
}

.searchFieldMenuField {
  padding: 8px 10px;
  border-bottom: 1px solid #e6e6e6;
}

.logUpdateButtons {
  display: flex;
  flex-direction: row;
  gap: 4px;
  padding: 16px 16px 0px 0px;
}

.notesBackground {
  background-color: #f8f8f8;
}

.dropdownMenuListing {

  width: fit-content;
  margin-top: 8px;
}

.scheduleNowOperation {
  width: fit-content !important;
  margin-top: 2px !important;
}

.AttachmentIcon {
  width: 100%;
  height: 10rem;
  border-radius: 6px;
  object-fit: contain;
  cursor: pointer;
}

.aboutFooter {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding-top: 16px;
}

.aboutFootervisible {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding: 16px;
}

.aboutFooterNote {
  display: flex;
  flex-direction: row;
  gap: 8px;
  padding-top: 16px;
}

.WIDTh {
  width: 100%;
}

.addCommentButton {
  width: fit-content;
}

.emailLogListCommentContainer {
  display: flex;
  padding: 0px 16px 16px 16px;
}

.commentLabelInputContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
}

.commentInputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.commentSectionMainContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
}

.commentListDetailOperationContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.commentUserDetail {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.commentActionButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.allCommentsContainer {
  padding: 16px;
  background-color: #f8f8f8;
  border-radius: 6px;
  height: 43.2vh;
}

.allCommentsFitContainer {
  padding: 16px;
  background-color: #f8f8f8;
  border-radius: 6px;
  height: fit-content;
}

.eachCommentContainer {
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 8px;
  border-bottom: 1px groove;
}

.editableComment {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.defaultIcon {
  position: absolute;
  top: -2px;
  left: 0px;
  width: 24px;
  height: 24px;
  border-radius: 0px 0px 12px 0px;
  background-color: #FFECEC;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sendEmailLabelFieldContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.sendEmailSubjectBodyContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
}

.sendEmailModalLabelDiv {
  min-width: 46px;
}

.ccBccText {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.ccBccLabel {
  cursor: pointer;
}

.ccBccLabel:hover {
  text-decoration: underline;
}

.papermenuclass {
  width: 20vw;
  height: 30vh;

}

.papermenuclassmeeting {
  width: 20vw;
  height: 41.302vw;

}

.taskLogListCommentContainer {
  display: flex;
  padding: 0px 16px 16px 16px;
}

.taskLogListnotCommentContainer {
  display: flex;
  padding: 16px;
}

.noteLogListCommentContainer {
  display: flex;
  padding: 0px 16px 16px 16px;
}

.noteTextEditor {

  display: flex;
  padding: 0px 16px 16px 16px;
}

.errorAndEditor {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.scheduleTimeMainContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

.AttachmentHeadCon {
  display: flex;
  gap: 24px;
  padding: 16px 16px 16px 16px;
  /* flex-wrap: wrap; */
  width: 100%;

  display: grid;
  grid-template-columns: 22.5% 22.5% 22.5% 22.5%;
}

.AttachSubHead {
  display: flex;
  flex-direction: column;
}

.AttachSubHeads {
  display: flex;
  gap: 8px;
  flex-direction: column;
}

.attachJustify {
  display: flex;
  justify-content: space-between;
  padding-top: 4px;
  cursor: pointer;
}

.emailSentAccordionDetail {
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  padding: 16px;
}

.sentEmailOperations {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
}

.recipntsAndOperationsButton {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.taskLogLoaderContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.LoaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 12px;
}

.widthLoader {
  width: 100px;
}

.crmLoaderButtonsSkeleton {
  display: flex;
  flex-direction: row;
  gap: 8px;
  cursor: pointer;
}

.AttachmentDocUi {
  width: 234px;
  height: 144px;
  display: flex;
  justify-content: center;
  background-color: #F8F8F8;
  border-radius: 6px;
}

.paddingVerticalSize {
  display: flex;
  padding-top: 4px;
  align-items: center;
}

.verticalLineAttach {
  height: 12px;
  width: 1px;
  border-left: 1px solid #e6e6e6;
  margin: 0px 8px;
}


.contactsNameLI {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 8px 10px;
}

.contactsNameLI:hover {
  box-shadow: inset 1px 0px 0px 0px #FF4848;
  background-color: #f8f8f8;
}

.parsedEmailLogData {
  margin-top: 4px;
}

.clicked {
  cursor: pointer;
}

.pinContainer {
  position: absolute;
}

.pinIcon {
  width: 18px;
  height: 18px;
}

.addDialogFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  gap: 10px;
}

/* add/edit company dialog tabs */
.tabsContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #e6e6e6;
  background-color: #ffffff;
}

.tabPrimary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 10px;
  border-bottom: none;
  border-radius: 16px 16px 0px 0px;
  background-color: #ffffff;
  cursor: pointer;
  width: 100%;
}

.tabPrimary:hover p {
  color: #222124 !important;
}

.tabSelected {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 10px;
  box-shadow: inset 0px -2px 0px 0px #ff4848;
  border-radius: 16px 16px 0px 0px;
  background-color: #ffffff;
  cursor: pointer;
  width: 100%;
}

.addCompanyFormContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
}

.AttachmentHeadScrollDetail {
  overflow: auto;
  max-height: 250px;
}

.containGmailBtn {
  display: flex;
  justify-content: center;
  align-items: center;

}

.CalendarApi {
  text-align: center;
}

.gBox {
  padding: 2rem;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 4px;
  gap: 16px;
}

.hline {
  border: 1px solid #E6E6E6;
  height: 6.875vw;
}

.ImageView {
  background-color: #ffffff;
  height: 50vh;
  border-radius: 6px;
}

.CenterView {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% -50%);
  translate: -50% -50%;
  width: 40;
  box-shadow: 2;
  padding: 4;
  border: none !important;

}

.CenterViewSubHead {
  display: flex;
  flex-direction: column;
  border: none !important;
}

.allCompaniesCountLabel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.allResultContainer {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 6px;
  background: #F8F8F8;
}

.companiesList {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.threadEmailStatsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-top: 1px solid #e6e6e6;
  padding-top: 16px;
}

.emailStatsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.eachThreadContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
}

.eachThreadBorder {
  border-bottom: 1px solid #e6e6e6;
  padding-bottom: 16px;
}

.detailLayoutContainerProprty {
  display: grid;
  grid-template-columns: 2fr 4fr;
  /* column-gap: 16px; */
  /* height: calc(100vh - 95px); */
}

.contactDetailLeftContainerProperty {
  padding: 16px;
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
  border-right: 1px solid #E6E6E6;
  box-shadow: 0px 1px 1px 0px rgba(24, 24, 28, 0.04), 0px 3px 4px 0px rgba(24, 24, 28, 0.04);
  max-width: 20vw;
  height: 70vh;
}

.contactDetailContainerHead {
  padding: 32px 64px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.contactDetailContainerSubHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.propertyACtion {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.dragClass {

  display: flex;
  align-items: center;

}

.paddingaction {
  padding: 8px 0px 16px 0px;
}

.attachmentContainer {
  display: flex;
  gap: 4px;
  align-items: center;
  background: #f8f8f8;
  padding: 8px;
  border-radius: 8px;
  width: max-content;
}

.attachment {
  display: flex;
  gap: 8px;
  align-items: center;
}

.Anchor {
  text-decoration: none;
}

.listLoaderContainer {
  padding: 1rem;
}

.paddingBottom {
  padding-bottom: 1rem;
}

.flexWithGap {
  display: flex;
  gap: 12px;
  padding-bottom: 1rem;
  align-items: center;
}

.loaderBg {
  padding: 1rem;
  background: #f8f8f8;
}

.listContactLoaderContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}