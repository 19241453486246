.defaultItem:hover {
    background-color: #f8f8f8 !important;
    box-shadow: inset 2px 0px 0px 0px #ff4848;
}

.dropdownMainContainer #inputField {
    cursor: pointer
}

.inputAutoComplete:hover {
    border: 1px solid #A1A0A3 !important;
}

.inputAutoComplete:focus {
    box-shadow: 0px 0px 0px 3px #EBECF0;
    border: 1px solid #222124;
}

.subItemLabel {
    padding: 8px 10px
}

.searchFieldContainer {
    padding: 8px 10px;
}

.inputContainer {
    width: 100%;
    margin: 4px 0px;
}

.input {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    border-radius: 6px;
    padding: 0px 10px !important;
    width: 100% !important;
    height: 40px !important;
    border: 1px solid #E0E0E0 !important;
}

.scrollList {
    overflow-y: auto;
}

.scrollList::-webkit-scrollbar {
    width: 5px;
    height: 5px
}


.scrollList::-webkit-scrollbar-thumb {
    background: #CBCBCB;
    border-radius: 3px;
}

.scrollList::-webkit-scrollbar-thumb:hover,
.scrollList::-webkit-scrollbar-thumb:hover {
    background-color: #5A5C60;
}