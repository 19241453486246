.labelFieldContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    width: 100%;
}

.labelFieldContainerBottomBorder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    width: 100%;
    border-bottom: 1px solid #efefef;
    padding-bottom: 16px;
}

.formContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    width: 100%;
    padding: 0px 0px 16px 0px;
}

.formContainerWOPadding {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    width: 100%;
    padding: 0px;
}

.formContainerStep2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    width: 100%;
    padding: 16px 16px 0px 16px;
}

.createPropFooter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    margin-left: auto;
}

.propertiesContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    padding: 24px 16px 16px 16px;
    margin-bottom: 16px;
    box-shadow: inset 0px 1px 0px 0px #e6e6e6;
}

.htmlEditor {
    /* box-shadow: inset -1px 0 0 #e6e6e6, inset 0 -1px 0 #e6e6e6, inset 1px 0 0 #e6e6e6, inset 0 1px 0 #e6e6e6; */
    background-color: #ffffff;
    border: 1px solid #e6e6e6;
    width: 100%;
}

.addLabelButtonPropLabel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.headerContainerInputVal {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    box-shadow: inset 1px 0px 0px 0px #e6e6e6;
    width: 100%;
    border-radius: 6px 6px 0px 0px;
}

.headerContainerInputValTable {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    width: 100%;
    background-color: #f6f6f6;
    border-width: 1px 1px 1px 0px;
    border-style: solid;
    border-color: #e6e6e6;
    padding: 12px;
}

.headerContainerInputValTable:hover {
    background-color: #e8e8e8;
}

.multiValueMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.multiValueSubContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-width: 0.5px 1px 0.5px 1px;
    border-style: solid;
    border-color: #e6e6e6;
    user-select: none;
    width: 100%;
}

.dropdownLabelContainer {
    width: 100%;
    height: 100%;
    max-height: 30vh;
}

.iconContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    width: 20%;
}

.deleteIconMainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    width: 20%;
    cursor: pointer;
}

.inputFieldContainer {
    padding: 8px 12px;
    width: 60%;
}

.toggleButtonContainer {
    padding: 8px 12px;
    width: 20%;
}

.inFormHeaderContainer {
    width: 20%;
}

.labelHeaderContainer {
    width: 60%;
}

.deleteDisableIconContainer {
    height: 30px;
    width: 30px;
    padding: 3px;
    border-radius: 6px;
    cursor: not-allowed;
}

.deleteIconContainer {
    height: 30px;
    width: 30px;
    padding: 3px;
    border-radius: 6px;
}

.deleteIconContainer:hover {
    background-color: #f8f8f8;
}

.borderRaidiusValueSubContainer {
    border-radius: 0px 0px 6px 6px;
}

.iconContainerLeftRadius {
    border-radius: 6px 0px 0px 0px;
}

.iconContainerRightRadius {
    border-radius: 0px 6px 0px 0px;
}

.emptyPropertyBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    height: 100%;
}

.emptyPropertyLabels {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.emptyPropertyDesc {
    width: 24.104vw;
    text-align: center;
}

.groupModalBody {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.groupModalBodyLabelInput {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.scrnMainCtnr {
    width: 100%;
    height: calc(100% - 155px);
}

.tableUpprContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 16px;
}

.listTableContainer {
    margin: 0px 16px 16px 16px;
    border: 1px solid #e6e6e6;
    height: calc(100% - 82px);
    border-radius: 6px;
}

.searhcFieldAndFilterContainer {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%
}

.filterLabel {
    padding: 4px 10px;
}

.filterContainer {
    display: flex;
    align-items: center;
    gap: 4px;
    width: 100%;
}

.fieldTypeList {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 8px 10px;
}

.fieldTypeList:hover {
    background-color: #f8f8f8;
    box-shadow: inset 2px 0px 0px 0px #ff4848;
}

.tableHeaderContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    border-radius: inherit;
    width: 100%;
    background-color: #f6f6f6;
    border-style: solid;
    border-color: #e6e6e6;
    white-space: nowrap;
    padding: 12px;
    height: 46px;
}

.noBottomBorder {
    border-width: 1px 1px 0px 1px;
}

.noBottomLeftBorder {
    border-width: 1px 1px 0px 0px;
}

.tableHeaderCheckboxCell {
    display: flex;
    opacity: 0;
}

.headerCntnrWthoutLabel {
    width: 20%;
}

.previewMainContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;
    background-color: #f8f8f8;
    padding: 32px;
}

.visiblity_container {
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
}

.grpFilterMenuMainContainer {
    /* padding: 8px 10px; */
    display: flex;
    flex-direction: column;
    /* gap: 8px; */
}

.groupNameSearchField {
    padding: 8px 10px;
}

.searchField {
    min-width: 21.615vw;
}

.filterMenuContainer {
    padding: 8px 0px;
    height: 30vh;
}

.loadingText {
    padding: 8px 10px;
}

.multipleValuesError {
    margin-top: 4px;
}