/* styles.module.css */

.addFilter {
  display: flex;
  padding: 0px 1rem;
  align-items: center;
}

.normalFiltersContainer {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  padding: 16px;
}

.oppositeFiltersContainer {
  display: flex;
  flex-direction: column-reverse;
  background-color: #FFFFFF;
  padding: 16px;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.filterCategoryLabel {
  margin-bottom: 8px;
  margin-top: 16px;
}

.accordionSummary {
  margin: 0 !important;
  padding: 0px !important;
}

.containEachOpenFilter {
  display: flex;
  justify-content: space-between;
  border: 1px solid #d3dae3;
  padding: 0.8rem;
  border-radius: 3px 3px 0 0;
  width: 100%;
  align-items: center;
}

.containEachFilter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #E6E6E6;
  padding: 10px 12px;
  border-radius: 6px;
  width: 100%;
}

.modalFooterRightData {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.containInput {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.sharedWithBox {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.containRadioBtns {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.bodyContainerView {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.modalFooterLeftContainer {
  display: flex;
  align-items: center;
  gap: 4px;
}

.bigModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  background-color: white;
  border: none;
  width: 75vw;
  max-height: 90vh;
  border-radius: 4px;
  padding: 1.5rem 0.5rem;
}

.containCrossIcon {
  display: flex;
  justify-content: space-between;
  padding: 0 1.5rem;
  align-items: center;
  cursor: pointer;
  height: 2rem;
}

.viewDeleteModalRightFooter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.eachViewHeader {
  display: flex;
  justify-content: space-between;
  padding: 16px;
}

.eachViewHeader {
  display: flex;
  justify-content: space-between;
  padding: 16px;
}

.allViewContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: calc(70vh - 192px);
}

.toolTipContainer {
  background: white;
  box-shadow: 0px 9px 66px #00000029;
  border-radius: 4px;
  z-index: 1;
}

.eachViewContainer {
  padding: 0;
  border-right: 1px solid #EFEFEF;
  height: calc(70vh - 195px);
}

.eachViewContain {
  padding: 0;
  border-right: 1px solid #EFEFEF;
  height: calc(70vh - 195px);
}

.borderFlex {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  /* border-bottom: 1px solid #D3DAE3; */
  cursor: pointer;
  gap: 4px;
}

.borderFlex span,
.borderFlex p {
  color: #FF4848 !important;
}

.noBorderFlex {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  /* border-bottom: 1px solid #D3DAE3; */
  cursor: pointer;
  gap: 4px;
}

.noBorderFlex:hover,
.borderFlex:hover {
  box-shadow: inset 2px 0px 0px 0px #FF4848;
  background-color: var(--actions-hover);
}

.noBorderFlex span {
  color: #FF4848;
}

.manageCardContain {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0px 16px 16px 16px;
  height: calc(100% - 50px);
}

.manageCardContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0px 16px 16px 16px;
  height: calc(100% - 50px);
  overflow-y: auto;
}

.loaderContainer {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.importModalColumnsContainer {
  height: 100%;
  overflow: auto;
  background: white;

}

.eachColumn {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: #F2F5F8;
  padding: 1rem 1rem 1.5rem 1rem;

}

@media (max-width: 768px) {
  .eachColumn {
    margin: 0 10vw;
  }
}

.column_title {
  color: #616A7D;
  font-weight: 400;
  font-size: clamp(0.75rem, 0.75vw, 2rem);
  align-self: center;
  background: white;
  padding: 0.6rem 1rem !important;
  text-transform: capitalize;
  white-space: nowrap;
  padding-right: 0;
}

.flexJustifyCenter {
  padding-left: 1rem;
}

.modalFooterRightData1 {
  display: flex;
  gap: 4px;
}

.employeeModalMainContainer {
  height: 100%;
}

.loaderContainer2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100% - 62px);
}

.noRecordsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 62px);
}

.employeeModalBody {
  height: 100%;
}

.employeeDetailContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #D3DAE3;
  padding: 20px 20px 20px 12px;
}

.viewMoreContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #D3DAE3;
  padding: 20px 20px 20px 12px;
}

.employeeDetail {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 8px;
}

.container {
  width: 100%;
  align-self: center;
  overflow: auto;
  background-color: white;
  box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.04), 0px 1px 1px rgba(24, 24, 28, 0.04);

}

.filtersContainer {
  display: flex;
  padding: 6px 4px;
  flex-wrap: wrap;
  gap: 4px;
}

.addFilter1 {
  display: flex;
  padding: 0px 1rem;
  cursor: pointer;
}

.stripContainer {
  display: flex;
  justify-content: space-between;
  background: white;
  border-radius: 6px;
}

/* .containCompanyCheckbox {
  display: flex;
  padding:3px;
} */

.companyContainer {
  min-width: 450px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* padding: 1rem; */
  background: white;
  border-radius: 6px;
  /* gap: 8px; */
}

.jobTitleHead {
  display: flex;
  padding: 8px 12px;
}

.jobTitleHead:hover {
  background-color: #F6F6F6;
  box-shadow: inset 2px 0px 0px #ff4848;
}

.containCompanyCheckbox:hover {
  background-color: #F6F6F6;
  box-shadow: inset 2px 0px 0px #ff4848;
}

.filter {
  /* gap: 8px; */
  display: flex;
  /* padding: 16px; */
  flex-direction: column;
}

.pad8 {
  padding: 4px 8px;
}

.filterLabel {
  padding: 4px 10px;
}

.filterTab {
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.04), 0px 1px 1px rgba(24, 24, 28, 0.04);
  background-color: #FFFFFF;
  border-radius: 6px;
}

.contactListMenuItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  padding: 14px 16px;
  cursor: pointer;
  white-space: nowrap;
}

.contactListMenuItem:hover {
  background-color: #F8F8F8;
  box-shadow: inset 2px 0px 0px 0px #FF4848;
}

.backdropProps {
  background-color: transparent;
}

.filterTab1 {
  filter: drop-shadow(0px 3px 4px rgba(24, 24, 28, 0.04)) drop-shadow(0px 1px 1px rgba(24, 24, 28, 0.04));

  display: flex;
  /* align-items: center; */
  gap: 8px;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  background-color: white;
  border: none;
  width: 60vw;
  border-radius: 4px;
  padding: 2rem 0.5rem;
  max-height: 90vh;

}

.icon {
  color: #7c7d7e;
  font-size: 20px;
  cursor: pointer;
}

.containModalHead {
  display: flex;
  justify-content: space-between;
  padding: 1rem 1rem 0 1rem;
  padding-top: 0;
}

.contain2inputs {
  display: flex;
  padding: 1rem;
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  height: 4.8rem;
}

.eachInputLeftBox {
  width: 100%;
  margin-right: 1rem;
}

.eachInputRightBox {
  width: 100%;
}

.createListTitle {
  color: #212833;
  font-size: clamp(0.9rem, 1.3vw, 2.6rem);
  font-weight: 500;
}

.dynamicFieldModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow-y: auto;
  background-color: white;
  border: none;
  width: 40vw;
  border-radius: 4px;
  padding: 1.5rem;
}

.dynamicContainCrossIcon {
  display: flex;
  justify-content: flex-end;
}

.mainContainer {
  padding: 1rem 0;
}

.flex {
  display: flex;
}

.label {
  color: #626b7e;
  font-size: clamp(0.8rem, 0.8vw, 2rem);
  font-weight: 500;
  align-self: center;
  padding: 10px;
  cursor: pointer;
}

.flexWithPadding {
  display: flex;
  padding-top: 0.5rem;
}

.title {
  color: #102f51;
  font-weight: 500;
  font-size: 20px;
  padding-top: 1rem;
}

.GapDisplay {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.modalBodyContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
}

.modalPadding {
  padding-top: 10px;
}

.modalRadio {
  display: flex;
  flex-direction: row;
  padding-top: 6px;
  gap: 15px;
}

.footerData {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-left: auto;
}

.containHead {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.containColumns {
  display: flex;
  justify-content: space-between
}

.searchContainer {
  padding-bottom: 16px;
  border-bottom: 1px solid #e6e6e6;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.containCotactColumns {
  height: calc(100% - 64px);
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-right: 8px;
  overflow: auto;

}

.containLeft {
  margin-bottom: 0;
  border-radius: 6px;
  height: 56vh;
  padding: 16px;
}

.containRight {
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: #F8F8F8;
}

.containScroll {
  display: flex;
  flex-direction: column;
  height: 56vh;
  gap: 12px;
  padding-right: 8px;

}

.dragLabelContainer {
  display: flex;
  align-items: center;
  gap: 4px;
}

.draggableDiv {
  display: flex;
  flex-direction: row;
  padding: 8px;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.04), 0px 1px 1px rgba(24, 24, 28, 0.04);
  justify-content: space-between;
}

.draggableMainDiv {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: transparent;
}

.iconLabelContainer {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.draggableDivLabel {
  display: flex;
  flex-direction: row;
  padding: 8px;
  gap: 6px;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.04), 0px 1px 1px rgba(24, 24, 28, 0.04);
}

.crossIcon {
  display: flex;
  cursor: pointer;
}

.columnLabelContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.containColumnMainContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.allColumnsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.flex1 {
  gap: 1rem;
  display: flex;
  justify-content: space-between;
}

.text {
  color: #212833;
  font-weight: 500;
  font-size: clamp(12px, 0.729vw, 24px);
  padding: 0.5rem 0;
  white-space: nowrap;
}

.containEachAction {
  display: flex;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  padding: 8px;
  gap: 1rem;
  width: 14vw;
  white-space: nowrap;
}

.exportModalFooter {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: auto;
}

.container1 {
  filter: drop-shadow(0px 3px 4px rgba(24, 24, 28, 0.04)) drop-shadow(0px 1px 1px rgba(24, 24, 28, 0.04));

  /* box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.04), 0px 1px 1px rgba(24, 24, 28, 0.04); */
  width: 100%;
  align-self: center;
  overflow: auto;
  background-color: white;
  border-radius: 6px;
}

.filtersContainer {
  display: flex;
  padding: 8px;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
}

.addFilter2 {
  margin-left: 4px;
}

.stripContainer {
  filter: drop-shadow(0px 3px 4px rgba(24, 24, 28, 0.04)) drop-shadow(0px 1px 1px rgba(24, 24, 28, 0.04));

  /* box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.04), 0px 1px 1px rgba(24, 24, 28, 0.04); */
  display: flex;
  justify-content: space-between;
  background: white;
  border-radius: 6px;
}

.containCheckbox {
  display: flex;
  padding: 8px 12px;
}

.containCompanyCheckbox {
  display: flex;
  padding: 8px 12px;
}

.seniorityContainer {
  min-width: 300px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* padding: 6px 1rem; */
  background: white;
  border-radius: 8px;
  /* gap: 8px; */
}

.companyContainer {
  min-width: 150px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* padding: 0.5rem 1rem; */
  background: white;
  border-radius: 8px;
  /* gap: 8px; */
}

.nameToolTipContainer {
  display: flex;
  padding: 16px;
}

.sortingModal {
  background-color: #FFFFFF;
  border-radius: 6px;
}

.containSaveText {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  box-shadow: inset 0px -1px 0px 0px #E6E6E6;
}

.saveViewDisplayTxt {
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  box-shadow: inset 0px -1px 0px 0px #E6E6E6;
}

.saveViewDisplayTxt p {
  white-space: normal !important;
}

.containApplyBtn {
  display: flex;
  justify-content: space-between;
  padding: 16px;
}

.rightFooterButton {
  display: flex;
  gap: 8px;
  align-items: center;
}

.peopleHeaderContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.04), 0px 1px 1px rgba(24, 24, 28, 0.04);

}

.viewContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media (max-width: 1024px) {
  .viewContainer {
    flex-direction: column-reverse;
  }
}

.peopleContainer {
  display: flex;
  flex-direction: row;
  gap: 6px;
  padding: 16px 0px 0px 16px;
}

.pinnedViewActions {
  z-index: 1;
  background: white;
  box-shadow: 0px 9px 66px #00000029;
  border-radius: 0 0 6px 6px;
}

.actionContainer {
  display: flex;
}

.saveViewButton {
  display: flex;
  align-items: center;
  padding: 0px 16px 0px 0px;
}

.containSearchImage {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  height: calc(100% - 278px);
  background: white;
  border-radius: 4px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  margin: 0px 16px 16px 16px;
  box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.04), 0px 1px 1px rgba(24, 24, 28, 0.04);
}

.searchImage {
  width: 19.141vw;
  object-fit: contain;
}

.imageContainer {
  display: flex;
  flex-direction: column;
}

.contentPara {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contentHeading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.headingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.containPersonalDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.socialIconsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.socialIconsContainer1 {
  display: flex;
  justify-content: center;
  align-self: center;
  padding-top: 1rem;
  gap: 4px;
  padding-bottom: 32px;
}

.containFacebook,
.containTwitter,
.containLinkedin {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #DBDBDB;
  border-radius: 6px;
  padding: 3px;
  padding-bottom: 1px;
  margin: 0 0.3rem;
  align-self: center;
  cursor: pointer;
}

.containCrownInfo {
  display: flex;
  padding: 1rem 4px;
  padding-bottom: 0;
}

.containBasicInfo {
  padding: 0 0rem 1rem 0rem;
  border-bottom: 1px solid #D3DAE3;
  position: relative;
  width: 100%;
}

.containUnlockedInfo {
  padding: 0 0rem 0rem 0rem;
  border-bottom: 1px solid #D3DAE3;
  position: relative;
  width: 100%;
}

.basicInfoLeft {
  display: flex;
  gap: 8px;
  width: 100%;
}

.containEachInfo {
  display: flex;
  justify-content: space-between;
  margin-top: 0.8rem;
}

.containAccountsTitle {
  display: flex;
  justify-content: space-between;
}

.containAccountsInfo {
  padding: 1rem 0;
  border-bottom: 1px solid #D3DAE3;
}

.containTechPara {
  padding: 0.5rem;
}

.containLockedInfo {
  position: absolute;
  top: 15%;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
  width: 90%;
}

.flexDis {
  display: flex;
  align-items: center;
  gap: 8px;
}

.flexDisplay {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-top: 8px;
}

.flexJustifyBetween {
  padding-top: 1rem;
  display: flex;
  justify-content: space-between;
}

.comapnyImage {
  width: 18px;
  height: 18px;
}

.userDetailContain {
  padding-top: 20px;
}

.userEachDetailContain {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0.5rem 0;
}

.spcBtwn {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.link {
  color: #FF4848;
  font-weight: 500;
  font-size: 13px;
  align-self: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-decoration: none;
}

.FLEX {
  display: flex;
  justify-content: center;
}

.unlockLabelContainer {
  text-align: center;
}

.unloackButton {
  margin: 16px 0px;
  padding: 16px 162px;
}

.labelIconContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.copyIcon {
  padding: 3px;
  cursor: pointer;
  border-radius: 6px;
  display: flex;

}

.copyIcon:hover {
  background-color: #F6F6F6;
}

.containCheckbox:hover {
  background-color: #F6F6F6;
  box-shadow: inset 2px 0px 0px #FF4848;
}


.socialIcon {
  color: #A1A0A3;
  font-size: 16px !important;
  height: 14px !important;
  width: 19px !important;
}

.copiedTextContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 4px;
  gap: 2px;
  border-radius: 3px;
  background-color: #E8F6ED;
}

.basicInfoUnset {
  filter: unset;
}

.basicInfoBlur {
  filter: blur(8px);
}

.borderFlex1 {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-bottom: 1px solid #D3DAE3;
  cursor: pointer;
  gap: 4px;
}

.borderFlex1:hover {
  box-shadow: inset 2px 0px 0px 0px #FF4848;

}

.tableBox {
  width: 100%;
  padding: 1rem;
  padding-top: 0;
  height: calc(100% - 261px);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.containAllContacts {
  background: #FFE9E9;
  padding: 10px;
  display: flex;
  justify-content: center;
  gap: 2px;
}

.containTable {
  height: 100%;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0px 1px 2px rgba(24, 24, 28, 0.06), 0px 4px 4px -4px rgba(24, 24, 28, 0.02);
}

.containTableSmall {
  height: calc(100% - 40px);
  background-color: white;
  border-radius: 6px;
  box-shadow: 0px 1px 2px rgba(24, 24, 28, 0.06), 0px 4px 4px -4px rgba(24, 24, 28, 0.02);
}

.compTableSelectedContainer {
  height: calc(100% - 68px - 40px);
}

.compTableContainer {
  height: 100%;
}

.susimg {
  width: 100%;
}

.blackListModal {
  margin: 1rem;
}

.toolTipContain {
  background: white;
  box-shadow: 0px 9px 66px #00000029;
  border-radius: 6px;
  width: 300px;
  box-shadow: unset;


}

.addToList {

  padding: 9px 12px;
  border-bottom: 1px solid #D3DAE3;

}

.containAll {
  height: 15rem;
  padding: 0 12px;
}

.searchList {
  padding-top: 8px;
}

.input {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 8px;
  color: #626B7E;
  border: 1px solid #D3DAE3;
}

.input:hover {
  border: 1px solid #ff4848;
}

.eachList {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 12px;
}

.addEachList {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  height: 36px;
  padding: 8px 0;
  cursor: pointer;
}

.addEachList:hover {
  background-color: #F6F6f6;
}

.dataText {

  display: flex;
  flex-direction: column;
}

.containAllLists {
  height: 197px;
  overflow-y: auto;
}

.containAllLists2 {
  height: 15rem;
  overflow: auto;
  padding: 12px 0;
  padding-bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.containHead {
  border-bottom: 1px solid #e6e6e6;
  padding: 0 12px 12px 12px;
}

.selectList {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 20px);
}

.createNewList {
  border-top: 1px solid #E6E6E6;
  padding: 12px;
  display: flex;
  justify-content: space-between;
}

.btnContainer {
  display: flex;
  gap: 8px;
}

.movBtnGen {
  display: flex;
  gap: 8px;
}

.cursorPointer {
  cursor: pointer;
  padding-left: 8px;
}

.tableSelectedContainer {
  height: calc(100% - 68px - 40px);
}

.tableSelectedContainerSmall {
  height: 100%;
}

.tableContainer {
  height: 100%;
}

.manage {
  padding: 0px 10px;
}

.containUserModal {
  padding-top: 16px;
  overflow: auto;
}


.containScroll::-webkit-scrollbar,
.containCotactColumns::-webkit-scrollbar,
.manageCardContainer::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.containScroll::-webkit-scrollbar-track,
.containCotactColumns::-webkit-scrollbar-track,
.manageCardContainer::-webkit-scrollbar-track {
  height: 5px;
}

.containScroll::-webkit-scrollbar-thumb,
.containCotactColumns::-webkit-scrollbar-thumb,
.manageCardContainer::-webkit-scrollbar-thumb {
  background: #CBCBCB;
  border-radius: 3px;
}

.containScroll::-webkit-scrollbar-thumb:hover,
.containScroll *::-webkit-scrollbar-thumb:hover,
.containCotactColumns::-webkit-scrollbar-thumb:hover,
.containCotactColumns *::-webkit-scrollbar-thumb:hover,
.manageCardContainer::-webkit-scrollbar-thumb:hover,
.manageCardContainer *::-webkit-scrollbar-thumb:hover {
  background-color: #5A5C60;
}













.containAllLists::-webkit-scrollbar,
.containUserModal::-webkit-scrollbar,
.modal::-webkit-scrollbar,
.containCommonTable::-webkit-scrollbar,
.importModalColumnsContainer::-webkit-scrollbar,
.containAllFilters::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.containAllLists::-webkit-scrollbar-track,
.containUserModal::-webkit-scrollbar-track,
.modal::-webkit-scrollbar-track,
.containCommonTable::-webkit-scrollbar-track,
.importModalColumnsContainer::-webkit-scrollbar-track,
.containAllFilters::-webkit-scrollbar-track {
  height: 5px;
}

.containAllLists::-webkit-scrollbar-thumb,
.containUserModal::-webkit-scrollbar-thumb,
.modal::-webkit-scrollbar-thumb,
.containCommonTable::-webkit-scrollbar-thumb,
.importModalColumnsContainer::-webkit-scrollbar-thumb,
.containAllFilters::-webkit-scrollbar-thumb {
  background: #626B7E;
  border-radius: 10px;
}

.containAllFilters {
  background-color: #F8F8F8;
}

.htmlEditor {
  /* box-shadow: inset -1px 0 0 #e6e6e6, inset 0 -1px 0 #e6e6e6, inset 1px 0 0 #e6e6e6, inset 0 1px 0 #e6e6e6; */
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  width: 100%;
}

.addDialogFooter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  gap: 10px;
}

.addContactFormLoaderContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.inputForm {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* Input Label Field */
.inputLabelField {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.labelButtonContainer {
  display: grid;
  grid-template-columns: 2fr 1fr;
  align-items: center;
  width: 100%;
}

.emptyViewCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}


.emptyViewCardBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.pointerCursor {
  cursor: pointer;
}