.container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.dashBoardTableOperationsAreHere {
  width: 100%;
  display: flex;
  padding: 16px;
  flex-direction: row;
  align-items: center;
  background-color: white;
  justify-content: space-between;
  border-radius: 6px 6px 0 0;
}

.dashBoardTableOperationsLeft {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  background-color: inherit;
  /* width: 60%; */
  gap: 8px;
}

.containsSearchAndNothingElse {
  width: 21.615vw;
}

.containsAutocomplete {
  width: 10vw;
}

.dashboardTableOperationsRight {
  width: 10%;
}

.clearAllStyle {
  padding-top: 5px;
}

.container {
  background-color: #e6e6e6;
  height: 100%;
  width: 100%;
  padding: 16px;
  justify-content: center;
  align-items: center;
}

.tableContainer {
  height: calc(100vh - 160px);
  margin: 0px 16px;
}
.tableContainerEmpty {
  height: calc(100vh - 160px);
  margin: 0px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tableStyle {
  height: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(24, 24, 28, 0.06),
    0px 4px 4px -4px rgba(24, 24, 28, 0.02);
  border-radius: 0 0 6px 6px;
}

.tableBoxheder {
  margin: 16px 16px 0 16px;
  border-radius: 6px 6px 0 0;
}

.containsCustomHeader {
  width: 100%;
  background-color: white;
}

.superContainer {
  /* height: 100%; */
  /* width: 100%; */
  /* display: flex; */
  padding: 0;
  height: 100vh;
  overflow: hidden;
  /* flex-direction: column; */
  background-color: #f8f8f8;
  /* align-items: center; */
}

.deleteButton {
  width: 4.792vw;
}
