/* Styles for the main container of the contact screen */
.contactScreenMainContainer {
  height: 100vh;
}

/* Styles for the tab header container */
.tabHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  background-color: #ffffff;
  filter: drop-shadow(0px 3px 4px rgba(24, 24, 28, 0.04)) drop-shadow(0px 1px 1px rgba(24, 24, 28, 0.04));
}

/* Styles for the left side of the tab header container */
.tabHeaderLeftContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  padding: 16px 16px 0px 16px;
}

/* Styles for the right side of the tab header container */
.tabHeaderRightContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  padding: 0px 16px 0px 0px;
}


/* Styles for the upper strip container of the table */
.tableUpperStripContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
  padding: 16px;
  margin: 0px 16px;
  background: #ffffff;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

/* Styles for the right side of the upper strip container of the table */
.tableUpperStripRightContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.detailLayoutContainer {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  /* column-gap: 16px; */
  height: calc(100vh - 95px);
  margin: 1rem;
}

.contactDetailContainer {
  display: flex;
  height: 100%;
  /* width: 56vw; */
  justify-content: center;
  border-radius: 6px;
  /* box-shadow: 0px 1px 1px 0px rgba(24, 24, 28, 0.04), 0px 3px 4px 0px rgba(24, 24, 28, 0.04); */
}

.contactDetailLeftContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 6px;
  background-color: #ffffff;
  box-shadow: 0px 1px 1px 0px rgba(24, 24, 28, 0.04), 0px 3px 4px 0px rgba(24, 24, 28, 0.04);
  max-width: 20vw;
}

.contactDetailRightContainer {
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius);
  max-width: 20vw;
  margin-left: 8px;
  gap: 16px;
  overflow-x: hidden;
}

.contactDetailMainContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 100%;
  padding-top: 16px;
}

.contactNameContainer {
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.copiedTextContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 4px;
  gap: 2px;
  border-radius: 3px;
  background-color: #E8F6ED;
}


.contactNameContainer #copiedTextContainer {
  visibility: hidden;
}

.contactNameContainer:hover #copiedTextContainer {
  visibility: visible;
  cursor: pointer;
}

.contactDetailNameDesig {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1px
}

.sortingModal {
  background-color: #FFFFFF;
  border-radius: 6px;
}

.containSaveText {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  box-shadow: inset 0px -1px 0px 0px #E6E6E6;
}

.saveViewDisplayTxt {
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  box-shadow: inset 0px -1px 0px 0px #E6E6E6;
}

.saveViewDisplayTxt p {
  white-space: normal !important;
}

.containApplyBtn {
  display: flex;
  justify-content: space-between;
  padding: 16px;
}

.rightFooterSaveViewMenuButton {
  display: flex;
  gap: 8px;
  align-items: center;
}

.containDropBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 60px 80px;
  border-radius: 6px;
  background-color: #ffffff;
  cursor: pointer;
  box-shadow: 0px 2px 4px -2px rgba(24, 24, 28, 0.06), 0px 4px 8px -2px rgba(24, 24, 28, 0.10);
}

/* Styles for ABout Contact Section */

.aboutContact {
  padding: 1rem;
  border-top: 1px solid #E6E6E6;
  width: 100%;
}

.aboutContactHead {
  padding: 32px;
  display: flex;
  /* flex-wrap: wrap; */
  /* justify-content: center;
  align-items: center; */
  /* width:100%; */
  gap: 12px;

  @media (min-width: 1200px) and (max-width: 1600px) {
    gap: 8px;
  }

}

.aboutContactDetails {
  margin: 1rem;
  width: 100%;
}

.aboutContactDetails::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.aboutContactDetails::-webkit-scrollbar-thumb {
  background: #CBCBCB;
  border-radius: 3px;
}

.aboutGap {
  padding: 20px 0px;
}

.papermenuclass {
  width: 20vw;
  height: 30vh;

}

.aboutFooter {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  padding: 16px;
  transition: 1666200s ease-in-out;
}


.skeletonPadding {
  padding: 0px 0px 20px 0px;
}

.skeletonPad {
  padding-bottom: 2px;
}

.skeletonPadName {
  padding: 0px 40px 2px 40px;
}

.displayAbout {
  display: flex;
  text-align: center;
  justify-content: center;
}

.searchImage {
  width: 19.141vw;
  object-fit: contain;
}

.imageContainer {
  display: flex;
  flex-direction: column;
}

.containSearchImage {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  height: calc(100% - 96px);
  background: white;
  border-radius: 6px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  margin: 16px;
  box-shadow: 0px 3px 4px rgba(24, 24, 28, 0.04), 0px 1px 1px rgba(24, 24, 28, 0.04);
}

.contentPara {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contentHeading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.dragDropContainer {
  height: calc(100% - 64px);
}

.labelDragDrop {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.fileUploadInput {
  display: none;
}

.mainImportContainer {
  background-color: #f9fafb;
  height: 100vh;
}

.imprtLabelContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.importLabelSubContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  width: 70%;
  text-align: center;
}

.uploadLabelContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.inputForm {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.addContactFormLoaderContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 1rem;
}

.contactDetailLeftSubContainer {
  overflow-y: auto;
  overflow-x: hidden;
}

.importTableHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 6px 6px 0 0;
}

.importTableMainContainer {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  margin: 1rem;
  height: calc(100vh - 96px);
  border-radius: 6px;
  box-shadow: 0px 4px 4px -4px rgba(24, 24, 28, 0.02), 0px 1px 2px 0px rgba(24, 24, 28, 0.06);
}

.importTableContainer {
  height: calc(100% - 68px);
}

.htmlEditor {
  background-color: var(--base-white);
  border: 1px solid var(--border-default);
  width: 100%;
  border-radius: var(--border-radius);
}

.htmlEditorError {
  background-color: var(--base-white);
  border: 1px solid var(--brand-primary);
  width: 100%;
  border-radius: var(--border-radius);
}

.inputLabelField {
  display: flex;
  flex-direction: column;
  gap: 2px
}

.searchFieldWidth {
  width: 21.615vw;
}

.accordionSummary {
  margin: 0 !important;
  padding: 0 !important;
}

.accrodionRoot {
  background-color: #ffffff;
  box-shadow: none;
  border: none;
  margin: 0px !important;
  width: 100%;
  border-radius: 6px !important;
  box-shadow: 0px 1px 1px 0px rgba(24, 24, 28, 0.04), 0px 3px 4px 0px rgba(24, 24, 28, 0.04) !important;
}

.accrodionRoot::before {
  height: 0px !important;
}

.summaryContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  width: 100%;
}

.summaryContainerOpen {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  width: 100%;
  border-bottom: 1px solid #efefef;
}

.summaryRightSection {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.accordionIcon {
  margin-top: 2px;
}

.verticalLine {
  height: 16px;
  width: 1px;
  border-left: 1px solid #e6e6e6;
  margin: 0px 8px;
}

.companyBodyContainer {
  display: flex;
  flex-direction: column;
  padding: 16px;
  height: 100%;
  cursor: pointer;
}

.companyDetailMainContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  width: 100%;
}

.companyDetailsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  cursor: pointer;
}

.socialLinksContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin: 20px 0px;
}

.companyDescriptionLabel {
  display: flex;
  width: 98%;
  flex-wrap: wrap;
}

.companyDetailContainer {
  display: flex;
  flex-direction: column;
  padding: 10px 16px;
  background-color: #f8f8f8;
  border-radius: 6px;
  width: 100%;
}

.companyDetailMainContainer {
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.contactDetailContainerHead {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: calc(100vh - 95px);
  overflow: auto;
}

.contactDetailContainerSubHead {
  background-color: #ffffff;
  margin: 0px 16px;
}

.contactDetailPadding {
  padding: 16px 16px 0px 16px;
}

.TaskHead {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}

.TaskHeadAttachment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  margin: 0px 16px;
}

.TaskSubHead {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.TaskCreateBtn {
  width: max-content;
}

.inputFile {
  display: none;
}

.emailLogListContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
}

.emptyEmailDetailMainContainer {
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
}

.emailScreenMainContainer {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0px 16px;
}

.emailLogLoaderContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-right: 11px;
}

.labelButtonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.emailLogButtonContainer {
  display: flex;
  flex-direction: row;
  align-self: center;
  gap: 8px;
}

.taskListContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* padding: 16px; */
  width: 100%;
}

.WIdthButton {
  width: max-content;
  display: flex;
  gap: 8px;
}

.HeadTaskContainerSubHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.taskScreenMainContainer {
  width: 100%;
  height: 100%;
  padding: 0px 16px;
  overflow: auto;

}

.noteContainer {
  width: 100%;
  height: 100%;
  padding: 0px 16px;
  overflow: auto;
}

.noComanyFoundContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 12px;
  padding: 16px;
}

.importSearchBar {
  min-width: 20.573vw;
}

.DisplayFlexAbout {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flexEachCompanyClosedPipeline {
  display: flex;
  gap: 4px;
  background-color: #E8F6ED;
  padding: 1rem;
  margin: 0rem 1rem;
  flex-direction: row;
  border-radius: 6px;
}

.displayColumnPipeline {
  display: flex;
  gap: 8px;
  flex-direction: column;
}

.flexEachContactPipeline {
  display: flex;
  gap: 4px;
  background-color: #f8f8f8;
  padding: 1rem;
  margin: 0rem 1rem;
  flex-direction: column;
  border-radius: 6px;
}

.accordionDetail {
  /* max-height: 70vh; */
  /* max height changed for deal right container */
  max-height: 35vh;
  overflow: auto;
}

.pipelineOuter {
  padding-bottom: 16px;
  border-bottom: 1px solid #e6e6e6;
}

.PipelineFlex {
  display: flex;
  gap: 4px;
}

.dealsEmptyScreenContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  height: calc(100vh - 64px - 56px - 32px);
  margin: 16px;
}