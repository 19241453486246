.emptyPropertyBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    height: 100%;
    width: 100%;
    background-color: #ffffff;
    border-radius: 6px;
    box-shadow: 0px 4px 4px -4px rgba(24, 24, 28, 0.02), 0px 1px 2px 0px rgba(24, 24, 28, 0.06);
    ;
}

.emptyPropertyLabels {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.emptyPropertyDesc {
    width: 24.104vw;
    text-align: center;
}

.buttonsContainer {
    display: flex;
    flex-direction: row;
    gap: 8px;
}