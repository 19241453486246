.mainContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    height: calc(100% - 84px);
    border-radius: var(--border-radius);
    border: 1px solid var(--border-default);
}

.sectionContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding: var(--padding);
    cursor: pointer;
}

.selectedSectionContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding: var(--padding);
    background-color: var(--primary-50);
    box-shadow: inset 2px 0px 0px 0px var(--brand-primary);
}

.formSectionListContainer {
    display: flex;
    flex-direction: column;
    border-right: 1px solid var(--border-default);
    height: 100%;
    width: 20%;
}

.formSectionListRightContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
    /* padding: var(--padding); */
    width: 80%;
}

.propertyListaAndPreview {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: calc(100% - 69px);
}

.propertyListingContainer {
    width: 30%;
    border-right: 1px solid var(--border-default);
    height: 100%;
}

.propertyPreviewSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--base-background-light);
    width: 70%;
    padding: var(--padding);
    height: 100%;
}

.footerSection {
    border-top: 1px solid var(--border-default);
    padding: var(--padding);
}

.propertiesSearchContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: var(--padding);
    border-bottom: 1px solid var(--border-default);
}

.accordionSummary {
    margin: 0 !important;
    padding: 0 !important;
}

.accrodionRoot {
    background-color: #ffffff;
    box-shadow: none !important;
    border: none;
    margin: 0px !important;
    width: 100%;
    border-radius: 6px !important;
}

.listingContainer {
    display: flex;
    flex-direction: column;
    height: calc(100% - 70px);
}

.summaryContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 12px;
    width: 100%;
    background-color: var(--base-background-light);
    border-bottom: 1px solid var(--border-default);
}

.summaryContainerOpen {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 12px;
    width: 100%;
}

.summaryLastContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 12px;
    width: 100%;
    background-color: var(--base-background-light);
}


.accordionDetail {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px !important;
}

.otherPropertiesListing {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px !important;
    border-top: 1px solid var(--border-default);
}

.otherPropertiesListingWoBorder {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px !important;
}

.propertyLabelContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    border-radius: var(--border-radius);
    background-color: var(--base-background-light);
}

.previewHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid var(--border-default);
    background-color: var(--grey-quatinary);
    width: 57%;
    padding: var(--padding);
    border-radius: var(--border-radius) var(--border-radius) 0px 0px;
}

.previewBody {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border-radius: 0px 0px var(--border-radius) var(--border-radius);
    background-color: var(--base-white);
    box-shadow: var(--small-shadow);
    height: calc(100% - 56px);
    width: 57%;
}

.multiValueMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 16px;
    padding: var(--padding);
}

.multiValueStaticMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 16px;
    padding: var(--padding);
    border-bottom: 1px solid var(--border-default);
}

.multiValueSubContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    border-radius: var(--border-radius);
    border: 1px solid var(--border-hover);
    width: 100%;
    padding: 10px;
}

.multiValueStaticSubContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    border-radius: var(--border-radius);
    width: 100%;
    padding: 10px;
    background-color: var(--border-disabled);
}

.formsFooter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.formsFooterRight {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

}

.htmlEditor {
    background-color: var(--base-white);
    border: 1px solid var(--border-default);
    width: 100%;
    border-radius: 6px;
}

.inputLabelContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    width: 100%;
}

.fieldsPreviewBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 16px;
}

.associationList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    padding: var(--padding);
}

.tooltipIcons {
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.formsFieldsLoadingMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 57%;
    gap: 16px;
    padding: var(--padding);
    background-color: var(--base-white);
}

.eachLoaderWidth {
    width: 100%;
}