.mainContainer:hover #lefticon {
    color: #ff4848 !important;
}

.mainContainer:hover #righticon {
    color: #ff4848 !important;
}

.mainContainer:hover #label {
    color: #ff4848 !important;
}

.mainContainer:active {
    background-color: #e8e8e8 !important;
}

.mainContainer:active #lefticon {
    color: #5f5f61 !important;
}

.mainContainer:active #righticon {
    color: #5f5f61 !important;
}

.mainContainer:active #label {
    color: #5f5f61 !important;
}