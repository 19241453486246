
.orange {
    background-color: #FEECDC !important;
    color: #FF8A4C !important;
    @media (max-width: 1600px) {
        padding: 4.5px !important;
      

    }
}

.orange #icon {
    @media (max-width: 1600px) {
        width: 16px;
        height: 16px;
    }
}

.green {
    background-color: #E8F6ED !important;
    color: #01A63E !important;
    @media (max-width: 1600px) {
        padding: 4.5px !important;
    }
}



.purple {
    background-color: #EEECFF !important;
    color: #9162FA !important;
    @media (max-width: 1600px) {
        padding: 4.5px !important;
    }
}



.blue {
    background-color: #EBF1FD !important;
    color: #2080F6 !important;
    @media  (max-width: 1600px) {
        padding: 4.5px !important;
    }
}



.yellow {
    background-color: #FFF5E5 !important;
    color: #F4A118 !important;
    @media  (max-width: 1600px) {
        padding: 4.5px !important;
    }
}

.teal{
    background-color: #F0FFFF !important;
    color: #0694A2 !important;
    @media  (max-width: 1600px) {
        padding: 4.5px !important;
    }

}

#icon {
    color: red !important
}