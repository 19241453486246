.default:hover {
    border-bottom: 1px solid #DBDBDB !important;
}

.default:focus {
    border-bottom: 1px solid #DBDBDB !important;
}

.textarea {
    width: 100%;
    padding: 1rem;
    border-bottom: 1px solid #dbdbdb;
    border-radius: 6px;
    padding: 10px;
    background-color: #ffffff;
    color: #A1A0A3;
}

.textarea:focus {
    border-bottom: 1px solid #DBDBDB !important;
}
.textarea:hover {
    border-bottom: 1px solid #DBDBDB !important;
}

#inputField::placeholder {
    color: #A1A0A3 !important;
}
