.orange:hover {
    background-color: #FEECDC !important;
    color: #FF8A4C !important;
}

.orange {
    @media   (max-width: 1600px) {
        padding: 4.5px !important;
    }
}

.orange #icon {
    @media   (max-width: 1600px) {
        width: 16px;
        height: 16px;
    }
}

.green {
    @media   (max-width: 1600px) {
        padding: 4.5px !important;
    }
}

.green:hover {
    background-color: #E8F6ED !important;
    color: #01A63E !important
}

.purple {
    @media   (max-width: 1600px) {
        padding: 4.5px !important;
    }
}

.purple:hover {
    background-color: #EEECFF !important;
    color: #9162FA !important;
}

.blue {
    @media   (max-width: 1600px) {
        padding: 4.5px !important;
    }
}

.blue:hover {
    background-color: #EBF1FD !important;
    color: #2080F6 !important;
}

.yellow {
    @media (min-width: 1200px) and (max-width: 1600px) {
        padding: 4.5px !important;
    }
}

.yellow:hover {
    background-color: #FFF5E5 !important;
    color: #F4A118 !important;
}

#icon {
    color: red !important
}