.formsMainScreenContainer {
    display: flex;
    flex-direction: column;
    margin: 16px 0px 16px 16px;
    height: calc(100vh - 6rem);
    background-color: var(--base-white);
    border-radius: var(--border-radius);
}

.formsHeaderContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    padding: var(--padding);
    border-bottom: 1px solid var(--border-default);
}

.selectFormContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    padding: var(--padding);
    border: 1px solid var(--border-default);
    border-radius: var(--border-radius);
}

.selectFormType {
    display: flex;
    width: 10.313vw;
}

.formsBodyContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: var(--padding);
    height: calc(100% - 85px);
}