.formControlLabel .MuiTypography-root {
    color: #5F5F61 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
}

.formControlLabel .MuiSvgIcon-root {
    height: 18px;
    width: 18px;
}

.radioButton .Mui-checked {
    color: #FF4848 !important;
    cursor: pointer !important
}

.radioButton:global(.Mui-checked) {
    color: #FF4848 !important;
    cursor: pointer !important
}

.radioButton:disabled {
    color: #EEEEEE !important
}