.propertiesMainContainer {
    height: calc(100vh - 6rem);
    background-color: #ffffff;
    margin: 16px 0px 16px 16px;
    border-radius: 6px;
    /* overflow: auto; */
}

.headerContainer {
    display: flex;
    border-bottom: 1px solid var(--border-default);
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    gap: 4px;
}

.bodyContainer {}

.tabsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #e6e6e6;
    padding: 16px;
    margin: 16px;
    gap: 12px;
    border-radius: 6px;
}

.tabPrimary {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 12px;
    gap: 10px;
    border-bottom: none;
    border-radius: 16px 16px 0px 0px;
    background-color: #ffffff;
    cursor: pointer;
}

.tabPrimary:hover p {
    color: #222124 !important;
}

.tabSelected {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 12px;
    gap: 10px;
    box-shadow: inset 0px -2px 0px 0px #ff4848;
    border-radius: 16px 16px 0px 0px;
    background-color: #ffffff;
    cursor: pointer;
}

.organizerlist {
    align-items: center;
    gap: 12px;
    padding: 8px 12px;
    display: flex;
    flex-direction: "row";
}

/* .organizerlist :hover{
        background-color: #F8F8F8;
        box-shadow: inset 2px 0px 0px 0px #FF4848 ,
    } */
.organizerlist:hover {
    background-color: #F8F8F8;

    box-shadow: #ff4848 1px 0px 0px inset !important;
}

.filterered {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-height: 200px;
    overflow: auto,
}

.modalFooterRightData {
    display: flex;
    gap: 8px;
}

.modalFooterRightData2 {
    display: flex;
    gap: 8px;
    justify-content: space-between;
    padding: 16px;
    border-top: 1px solid var(--border-default);
}

.DisplayFlex {
    display: flex;
    gap: 8px;
}

.StageNameWidth {
    width: 38%;
    border-right: 1px solid var(--border-default);
    padding: 12px 12px 12px 0px;
}

.DealPropbabilityWidth {
    width: 38%;
    border-right: 1px solid var(--border-default);
    padding: 12px;
}

.StageColorWidth {
    width: 30%;
    border-right: 1px solid var(--border-default);
    padding: 12px;
}

.actionCellWidth {
    width: 6%;
    padding: 12px;
}

.WidthDrop {
    width: 10vw;
}

.HeightSUbContainer {
    height: calc(100vh - 360px);
    overflow: auto
}

.StageProbability {
    display: flex;
    border: 1px solid #e6e6e6;
    margin: 0px 16px;
    background-color: var(--actions-hover);
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
    padding: 0px 16px;
}

.MultiDealcss {
    padding: 0px 16px 16px 16px;
}

.headerContainerInputValTable:hover {
    background-color: #e8e8e8;
}

.multiValueMainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
}

.multiValueSubContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-width: 0.5px 1px 0.5px 1px;
    border-style: solid;
    border-color: #e6e6e6;
    user-select: none;
    width: 100%;
    padding: 0px 16px;
}

.dropdownLabelContainer {
    width: 100%;
    height: 100%;
    max-height: 30vh;
}

.iconContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 0px;
    /* width: 20%; */
}

.deleteIconMainContainer {
    display: flex;
    justify-content: left;
    align-items: center;
    cursor: pointer;
    width: 6%;
    padding-left: 12px
}

.inputFieldContainer {
    padding: 8px 12px;
    width: 100%;
}

.colorPickerFieldContainer {
    padding: 8px 12px;
    width: 100%;
}

.toggleButtonContainer {
    padding: 8px 12px;
    /* width: 20%; */
}

.inFormHeaderContainer {
    /* width: 20%; */
}

.labelHeaderContainer {
    /* width: 60%; */
}

.DEAl {
    border-top: 1px solid #e6e6e6;
    padding: 0px 1px 1px 1px;
}

.modalBody {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.tabsContainerSub {
    display: flex;
    gap: 12px;
    align-items: center;
}

.Pointer {
    cursor: pointer;
}

.marginBottomIfErr {
    margin-bottom: 20px;
}

.mainContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 4px;
    height: calc(100vh - 250px);
}

.descriptionLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.borderRaidiusValueSubContainer {
    border-radius: 0px 0px 6px 6px;
}

.iconFieldContainer {
    display: flex;
    flex-direction: row;
    width: 38%;
}

.middleInputContainer {
    padding: 8px 12px;
    width: 38%;
}

.colorPickerContainer {
    display: flex;
    flex-direction: row;
    width: 30%;
}

.actionFieldContainer {
    display: flex;
    flex-direction: row;
    width: 6%;
}

.dropdownMenuListing {
    width: fit-content !important;
    margin-top: 2px !important;
    margin-left: -1.2vw !important;
}